import { render, staticRenderFns } from "./quotePriceInfo.vue?vue&type=template&id=44bf36bd&scoped=true"
import script from "./quotePriceInfo.vue?vue&type=script&lang=js"
export * from "./quotePriceInfo.vue?vue&type=script&lang=js"
import style0 from "./quotePriceInfo.vue?vue&type=style&index=0&id=44bf36bd&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44bf36bd",
  null
  
)

export default component.exports