<!--
 * @Description: 服务商详情

-->
<template>
  <div class="caseDetailPage">
    <!-- 头部区域 -->
    <outSideHeader />
    <div class="header-cover" v-show="showHeader">
      <div class="tabBox">
        <a-tabs v-model="activeKey" @change="handleChangeTag">
          <a-tab-pane key="serviceContent" tab="公司介绍"></a-tab-pane>
          <a-tab-pane key="serviceInfo" tab="合作方式"></a-tab-pane>
          <a-tab-pane key="successCase" tab="产品案例"></a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div class="case-container">
      <!-- banner -->
      <div class="case-container-head">
        <div style="display: flex;">
          <a-carousel
            autoplay
            arrows
            :dots="false"
            style="width: 640px;"
            v-if="bannerList.length>0"
            ref="SwiperRef"
            :beforeChange="handleChangeTopSwiper"
          >
            <template #prevArrow>
              <img src="/banner_arrow_right.png" alt="" class="arrow " />
            </template>
            <template #nextArrow>
              <img src="/banner_arrow_left.png" alt="" class="arrow " />
            </template>
            <div class="bannerCover" v-for="item in bannerList">
              <img :src="item" />
            </div>
          </a-carousel>
          <img src="/ent_empty.png" alt="" style="width: 640px;height: 360px" v-else />
          <div class="supplierBase">
            <div class="baseName">{{ businessBase.name || ' ' }}</div>
            <div>
              <img src="/location.png" alt="" class="location" />
              <span class="baseAddress">{{ businessBase.cityName || '-' }} {{ businessBase.areaName || '' }}</span>
              <!--              <span class="baseLink">查看地图</span>-->
            </div>
            <div class="base-box">
              <div class="base-box-item">
                <span class="item-num">{{ baseInfo.yearTurnoverDictName }}	</span>
                <span class="item-desc">年营业额</span>
              </div>
              <div class="base-box-item">
                <span class="item-num">100%</span>
                <span class="item-desc">履约率</span>
              </div>
              <div class="base-box-item" style="padding-right: 0px">
                <span class="item-num">4.5</span>
                <span class="item-desc">综合评分</span>
              </div>

            </div>
            <div style="display: flex;flex-direction: column">
              <div class="base-left-title">主营包装：<span class="base-right-desc">{{ baseInfo.mainPackagingDictName
                }}</span>
              </div>
              <div class="base-left-title" style="padding: 8px 0px">质量认证：<span
                class="base-right-desc">{{ baseInfo.authMethodDictName || '-' }}</span></div>
              <div class="base-left-title">供货区域：<span class="base-right-desc">全国</span></div>
            </div>
            <div class="send-phone-box">
              <a-button class="send-phone-action" @click="qrCodeVisible=true" v-if="baseInfo.contactPhone">
                <!--             <a-icon type="phone" rotate="100"  :style="{ color: '#FFFFFF',fontSize: '20px' }" />-->
                <img src="@/assets/send-phone.png" style="width: 20px ;height: 20px;margin-right: 6px">
                电话询价
              </a-button>

            </div>
          </div>
        </div>
        <ProductSwiper class="case-container-min" ref="ProductSwiperRef" :productList="bannerList"
                       @changeSwiper="handleChangeSwiper" v-if="bannerList.length >0" />
      </div>
      <div class="tabBox">
        <a-tabs v-model="activeKey" @change="handleChangeTag">
          <a-tab-pane key="serviceContent" tab="公司介绍"></a-tab-pane>
          <a-tab-pane key="serviceInfo" tab="合作方式"></a-tab-pane>
          <a-tab-pane key="successCase" tab="产品案例"></a-tab-pane>
        </a-tabs>
      </div>
      <div class="serviceCover" id="serviceContent">
        <div class="part-title">
          <span>公司介绍</span>
        </div>
        <div class="card">
          {{ baseInfo.companyDesc || '-' }}
        </div>
      </div>
      <div class="serviceCover" id="serviceInfo">
        <div class="part-title">
          <span>合作方式</span>
        </div>
        <div class="info-area">
          <a-descriptions :column="2" bordered>
            <a-descriptions-item label="最小起订量">
              {{ baseInfo.minOrderCountDictName || '-' }}
            </a-descriptions-item>
            <a-descriptions-item label="加工方式">
              {{ baseInfo.processingMethodDictName || '-' }}
            </a-descriptions-item>
            <a-descriptions-item label="打样测试">
              {{ baseInfo.sampleTestDictName || '-' }}
            </a-descriptions-item>
            <a-descriptions-item label="印刷方式">
              {{ baseInfo.printingMethodDictName || '-' }}
            </a-descriptions-item>
            <a-descriptions-item label="成型">
              {{ baseInfo.formingDictName || '-' }}
            </a-descriptions-item>
            <a-descriptions-item label="原辅材料">
              {{ baseInfo.primaryMaterials || '-' }}
            </a-descriptions-item>
            <a-descriptions-item label="开票点数">
              {{ businessBase.invoiceRate * 100 + '%' }}
            </a-descriptions-item>
            <a-descriptions-item label="支持外贸">
              {{ baseInfo.supportTradeDictName || '-' }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </div>
      <div class="serviceCover" id="successCase" style="margin-bottom: 82px">
        <div class="part-title">
          <span>产品案例</span>
        </div>
        <div :class="successfulCase.length>0?'successfulCase':'info-area'">
          <div :style="successfulCase.length>0?'padding:24px':''">
            <div style="display: flex;gap: 24px;flex-wrap: wrap;box-sizing: border-box">
              <div v-for="i in successfulCase" style="box-sizing: border-box;width: 270px" @click="toShowDetail(i)">

                <img :src="i.productPicture[0]" class="product-img">
                <div class="product-name">{{ i.productName }}</div>
                <div>
                  <span class="product-type">{{ i.productCategoryDictName }}</span>
                </div>
              </div>
            </div>
            <div class="page-area">
              <a-pagination v-if="successfulCase.length>=8" v-model="pageNum" :pageSize="20" :total="total"
                            show-less-items @change="loadMore" />
            </div>
          </div>
          <a-empty
            class="empty-case"
            image="/hotDesigner/emptyCase.png"
            description="暂无数据"
            style="  color: #999999;"
            v-if="!successfulCase.length"
          ></a-empty>
        </div>
      </div>

    </div>

    <a-modal v-model="qrCodeVisible" title="电话询价" @ok="qrCodeVisible=false" width="600px">
      <template slot="footer">
        <a-button key="submit" type="primary" @click="qrCodeVisible=false">
          返回
        </a-button>
      </template>
      <div
        style="background-color: #F7F7F7; border-radius: 4px;width:536px;height: 342px;display: flex;justify-content: center;flex-direction: column">
        <div class="qrCodeTitle" style="text-align: center">{{ baseInfo.contactName || '-' }} |
          {{ baseInfo.departmentDictName || '-' }}
        </div>
        <div class="qrCodePhone" style="text-align: center">{{ baseInfo.contactPhone }}</div>
        <VueQrcode
          v-if="qrLink!=null"
          :value="qrLink"
          :options="QrCodeOpt"
          style="margin: 0 auto"
        ></VueQrcode>
        <div class="call" style="text-align: center">扫码拨号</div>
      </div>
    </a-modal>
    <div class="case-container-head-detail" v-if="caseDetailShow"
         style="position: fixed;top: 0px;bottom: 0px; z-index: 999999;width: 100%;height: 100%; background: rgba(0, 0, 0, 0.5);display: flex;flex-direction: column;">
      <div style="display: flex;margin: 210px 360px 8px 360px;position: relative;justify-content: center">
        <div
          style="width: 720px;display: flex;justify-content: flex-end;position: absolute;padding-top: 16px;padding-right: 16px;"
          @click="toHiddeCase">
          <img src="@/assets/close-case-img.png" class="close-case">
        </div>
        <a-carousel
          autoplay
          arrows
          :dots="false"
          style="width: 720px;"
          v-if="caseItem.productPicture.length>0"
          ref="SwiperRef"
          :beforeChange="handleChangeTopSwiper"
        >
          <template #prevArrow>
            <img src="/hover-right.png" alt="" class="arrow " style="width: 40px;height: 40px;z-index: 600;" />
          </template>
          <template #nextArrow>
            <img src="/hover-left.png" alt="" class="arrow " style="width: 40px;height: 40px" />
          </template>
          <div class="bannerCover" v-for="i in caseItem.productPicture" style="position: relative">
            <img :src="i" style="width: 720px" />

          </div>

        </a-carousel>
        <div class="case-desc" style="z-index: 700;bottom: 0px;padding:50.5px 24px 19px 24px">
          <div class="case-title">{{ caseItem.productName }}</div>
          <div class="case-caseDesc">{{ caseItem.productDesc }}</div>
        </div>
      </div>
      <ProductSwiper class="case-container-min" ref="ProductSwiperRef" :productList="caseItem.productPicture"
                     @changeSwiper="handleChangeSwiper" v-if="caseItem.productPicture.length >0"
                     style="display: flex;justify-content: center" />
    </div>
  </div>
</template>

<script>

import outSideHeader from '@/components/tools/outSideHeader.vue'
import { getAction, postAction } from '@/api/manage'
import ProductSwiper from './modules/productSwiper.vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
import VueQrcode from '@chenfengyuan/vue-qrcode'

Vue.use(VueViewer)
const handleScroll = (that) => {
  let menuItems = [
    { id: 'serviceContent', label: '服务内容' },
    { id: 'serviceInfo', label: '服务详情' },
    { id: 'successCase', label: '成功案例' },
    { id: 'aboutShop', label: '关于商家' }
  ]

  const scrollPos = window.scrollY + 132

  if (window.scrollY >= 513) {
    that.showHeader = true
  } else {
    that.showHeader = false
  }
  menuItems.forEach((item) => {
    const section = document.getElementById(item.id)

    if (section && scrollPos >= section.offsetTop) {
      that.activeKey = item.id
    }
  })
}

function splitArrayIntoPairs(arr) {
  // 创建一个空数组来存放结果
  let result = []

  // 遍历数组，从索引0开始，步长为2
  // 注意：由于我们每次处理两个元素，所以需要确保索引小于数组长度减1
  for (let i = 0; i < arr.length - 1; i += 2) {
    // 将当前索引及其后一个索引的元素组成一个子数组，并添加到结果数组中
    result.push([arr[i], arr[i + 1]])
  }

  return result
}

export default {
  components: {
    outSideHeader,
    ProductSwiper,
    VueQrcode
  },
  data() {
    return {
      bannerList: [],
      baseInfo: {},
      activeKey: 'serviceContent',
      showHeader: false,
      serviceDesc: '',
      tagList: [],
      successfulCase: [],
      shopScoreList: [],
      shopDesc: [],
      shopHonor: [],
      businessData: {},
      businessBase: {},
      businessDeviceList: [],
      qrCodeVisible: false,
      qrLink: null,
      QrCodeOpt: {
        errorCorrectionLevel: 'H',
        width: 160,
        height: 160,
        quality: 0.3,
        margin: 1
        // color: {
        //   dark: "#010599FF",
        //   light: "#FFBF60FF",
        // },
      }, total: 0,
      pageNum: 1,
      id: null,
      caseItem:[],
      caseDetailShow:false

    }
  },
  created() {
    let id = this.$route.query.id
    id && this.handleLoadData(id)
    this.getQueryByBusinessBase(id)
    this.id = id
    this.getSupplierProductPageQuery()
  },
  destroyed() {
    const that = this

    window.removeEventListener('scroll', () => handleScroll(that))
  },
  methods: {
    handleChangeTopSwiper(from, to) {
      let rowIndex = to
      this.$refs.ProductSwiperRef.handleJumpIndex(rowIndex)
    },
    handleScrollInit() {
      const that = this

      window.addEventListener('scroll', () => handleScroll(that))
    },
    handleChangeSwiper(index) {
      this.$refs.SwiperRef.goTo(index, false)
    },
    handleChangeTag() {
      // 锚点跳转
      // const element = document.getElementById(this.activeKey);
      // element.scrollIntoView({ behavior: 'smooth' }); // 平滑滚动
      const element = document.getElementById(this.activeKey)
      // 注意：element.offsetTop 获取的是相对于其offsetParent的顶部距离
      // 如果你想要滚动到元素顶部正好对齐视窗顶部，应该直接使用 element.offsetTop
      // 但如果页面上有复杂的布局或定位，可能还需要考虑其他因素，如滚动条位置等
      window.scrollTo({
        top: element.offsetTop - 120, // 滚动到元素的顶部
        behavior: 'smooth' // 平滑滚动
      })
    },
    handleViewImage(index) {
      this.$viewerApi({
        images: this.shopHonor,
        initialViewIndex: index
      })
    },
    handleLoadData(id) {
      getAction(`/v2/business/service/provider/queryByBusinessId`, { businessId: this.$route.query.id }).then((res) => {
        const { success, data } = res
        if (success) {
          // this.list = data
          const { banner, serviceDetail, shopDesc, tags, shopHonor, successfulCase, shopScore, companyLogo } = data
          this.baseInfo = data
          this.bannerList = []
          if (banner) {
            this.bannerList= banner.split(',')
          }
          this.qrLink = 'https://b2b.baidu.com/mphone?call_phone=' + (data.contactPhone)

          this.handleScrollInit()
        }
      })

    },
    //获取企业信息
    getQueryByBusinessBase(id) {
      getAction('/v2/business/basic/info/queryByBusinessId', { businessId: id }).then((res) => {
        const { success, data, message } = res
        if (success) {
          // data.concatPhone = '18612983759'
          // data.concatDepartment = '市场部'
          // data.concat = '周现广'
          this.businessBase = data
        }
      })
    },

    //获取主营产品
    getSupplierProductPageQuery(flag) {
      if (flag) {
        this.pageNum = 1
      }
      postAction('/v2/businessService/case/case/pageList', {
        businessId: this.id,
        status: 1,
        current: this.pageNum,
        size: 20
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          const { list, total } = data
          list.map(item=>{
            if(item.productPicture)
            {
              item.productPicture=item.productPicture.split(',')
            }
          })
          this.successfulCase = list || []
          this.total = total
        }
      })
    }, loadMore() {
      this.getSupplierProductPageQuery()
    },
    toShowDetail(item) {

      this.caseItem = item ||[]
      this.caseDetailShow = true
    },
    toHiddeCase() {
      this.caseItem = null
      this.caseDetailShow = false
    }


  }
}
</script>

<style lang="less" scoped>
.card {
  background: #fff;
  padding: 24px;
  border-radius: 8px;

}

.caseDetailPage {
  background: #f3f5f9;
  padding-top: 56px;
  min-height: 100vh;

  .case-container {
    margin: 0 auto;
    width: 1203px;
    overflow: hidden;
    margin-top: 26px;

    .case-container-head {
      padding: 24px 26px;
      background: #ffffff;
      border-radius: 6px;
    }

    .page-title {
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 500;
      line-height: 33.6px;
      text-align: left;
      color: #131212;
      margin-bottom: 30px;
    }

    .bannerCover {
      width: 640px !important;
      height: 480px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 16/9;
        border-radius: 8px;
      }
    }

    .slideImg {
      width: 148px;
      height: 86px;
      border-radius: 6px;
    }

    ::v-deep .ant-carousel {
      .slick-arrow {
        display: none !important;
      }

      &:hover {
        .slick-arrow {
          display: block !important;
        }
      }
    }

    ::v-deep .ant-carousel .slick-prev {
      left: 0;
      z-index: 2;
    }

    ::v-deep .ant-carousel .slick-next {
      right: 0;
      z-index: 2;
    }

    img.arrow {
      width: 38px;
      height: 36px;
    }
  }

  .tabBox {
    margin-top: 25px;
    background: #fff;
    border-radius: 8px;
    padding: 10px 24px 0;
    box-sizing: border-box;

    ::v-deep .ant-tabs-tab {
      padding: 12px 0 20px;
      font-size: 16px;
    }

    ::v-deep .ant-tabs-bar {
      margin-bottom: 0;
      border-color: transparent;
    }
  }

  .header-cover {
    position: fixed;
    width: 100%;
    top: 56px;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 1px 8px 0px #0000000a;
    border-top: 1px solid #f1f1f1;
    z-index: 23;

    .tabBox {
      margin-top: 0;
      width: 1112px;
    }
  }

  .part-title {
    display: flex;
    align-items: center;
    height: 48px;
    line-height: 48px;
    margin-bottom: 16px;
    margin-top: 16px;

    &::before {
      display: block;
      content: '';
      width: 13px;
      height: 32px;
      margin-right: 10px;
      background: url('/hotDesigner/line_tag.png');
      background-size: 100% 100%;
      margin-top: 10px;
    }

    span {
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      color: #000;
    }
  }

  .serviceCover {
    width: 100%;

    .info-area {
      background: #fff;
      border-radius: 8px;
      padding: 24px;
      box-sizing: border-box;

      .table-title {
        width: 100%;
        background: linear-gradient(180deg, #fffaf6 0%, rgba(255, 255, 255, 0.46) 100%);
        height: 41px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        border: 1px solid #f1f1f1;
        border-bottom: none;

        .title {
          font-family: Helvetica Neue;
          font-size: 16px;
          font-weight: 700;
          line-height: 16px;
          text-align: left;
          color: #333;
        }

        .last-right {
          display: flex;
          align-items: center;

          span.label {
            font-family: Helvetica Neue;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            text-align: left;
            color: #666;
          }

          span.num {
            font-family: Helvetica Neue;
            font-size: 13px;
            font-weight: 700;
            line-height: 14px;
            text-align: left;
            color: #ff6900;
          }
        }
      }

      .info-table {
        width: 100%;

        .row-box {
          td.typeA {
            width: 262px;
            padding: 16px;
            box-sizing: border-box;
            font-family: Helvetica Neue;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
            text-align: left;
            color: #333;
            border: 1px solid #f1f1f1;
            border-right: none;
            border-bottom: none;
          }

          td.typeB {
            padding: 7px 8px;
            width: 262px;
            box-sizing: border-box;
            color: #333;
            font-size: 13px;
            line-height: 20px;
            border: 1px solid #f1f1f1;
            border-right: none;
            border-bottom: none;
          }

          td.typeC {
            background: #f5faff;
            padding: 7px 8px;
            font-size: 13px;
            line-height: 20px;
            color: #333;
            border: 1px solid #f1f1f1;
            border-bottom: none;
          }

          td.typeD {
            padding: 7px 16px;
            width: 524px;
            box-sizing: border-box;
            color: #333;
            font-size: 13px;
            line-height: 20px;
            border: 1px solid #f1f1f1;
            border-right: none;
            border-bottom: none;
          }

          .success {
            width: 16px;
            height: 16px;
          }
        }
      }

      .content-area-inline {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        .label {
          color: #131212;
          font-size: 14px;
          line-height: 20px;
        }

        .text-content {
          line-height: 24px;
          color: #666666;
        }
      }

      .tags-area {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 14px 14px;

        .tag-item {
          padding: 0 8px;
          line-height: 24px;
          font-size: 12px;
          color: #ff6026;
          background-color: #fff2ea;
          border-radius: 2px;
        }
      }

      .number-areas {
        width: 680px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;

        &-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 88px;
          height: 40px;

          .num {
            font-family: Helvetica Neue;
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            color: #ff6026;
          }

          .label {
            font-family: Helvetica Neue;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            text-align: center;
            color: #999;
          }

          .info {
            display: flex;
            align-items: center;

            .star-icon {
              width: 14px;
              height: 14px;
              cursor: pointer;
              margin-left: 4px;
            }
          }
        }
      }

      .coverBanner {
        width: 100%;
        height: auto;
        border-radius: 8px;
        margin: 24px 0;
      }

      .text-context {
        margin-top: 20px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #666;
        margin-bottom: 0;
      }

      .part-title-new {
        background: linear-gradient(90deg, #ffeadb 0%, rgba(255, 227, 207, 0.01) 100%);
        padding: 8px 12px;
        box-sizing: border-box;
        font-family: Helvetica Neue;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        color: #ff6026;
        margin-top: 24px;
      }

      .honor-container {
        margin-top: 24px;
        display: flex;
        grid-gap: 10px 10px;

        img.cover-box {
          width: 220px;
          height: 148px;
          border-radius: 8px;
          cursor: pointer;
        }
      }

      .company-top-info {
        display: flex;
        align-items: center;
        padding-bottom: 25px;
        border-bottom: 1px dashed #eeeeee;
        margin-bottom: 26px;

        .avatar {
          width: 56px;
          height: 56px;
          margin-right: 12px;
          border-radius: 50%;
        }

        .rest-company-info {
          height: 68px;
          display: flex;
          justify-content: space-between;
          flex: 1 0 0;
          min-width: 0;

          .main-info {
            height: 100%;
            width: 486px;
            display: flex;
            align-items: center;

            h3.shopName {
              color: #333;
              font-size: 16px;
              font-weight: 700;
              line-height: 16px;
              text-align: left;
              margin-bottom: 12px;
            }

            .header-tag-main {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              grid-gap: 8px 6px;

              .tag-box-item {
                // height: 16px;
                // line-height: 16px;
                text-align: center;
                padding: 1px 4px;
                font-size: 12px;
                color: #fff;
              }

              .bg_1 {
                background: #1a9efd;
              }

              .bg_2 {
                background: #ff6901;
              }

              .bg_3 {
                background: #0652d9;
              }

              .bg_4 {
                background: #01a04b;
              }

              .bg_5 {
                background: #fff7e7;
                color: #97825b;
              }
            }
          }

          .action-btn {
            width: 110px;
            height: 36px;
            font-size: 14px;
            font-weight: 600;
            margin-top: 16px;

            img.message-me {
              width: 16px;
              height: 16px;
              margin-right: 6px;
            }
          }
        }
      }
    }

    .successfulCase {
      background: #fff;
      border-radius: 8px;
      overflow: hidden;

      img.banner-img {
        width: 100%;
        height: auto;
        margin-bottom: 32px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .empty-case {
        margin: 60px auto;

        ::v-deep .ant-empty-image {
          height: 180px;
        }

        ::v-deep .ant-empty-description {
          color: #999999;
        }
      }
    }
  }
}

.supplierBase {
  display: flex;
  flex-direction: column;
  padding: 24px 0px 24px 24px;
  width: 100%;

  .baseName {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 39px;
    display: flex;
    align-items: center;
    color: #131212;
    padding-bottom: 8px;
  }

  .baseAddress {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    text-align: center;
    color: #666666;
  }

  .baseLink {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    /* 品牌主题色 */
    color: #FF6026;
    padding-left: 16px;

  }

  .base-box {
    font-family: 'PingFang SC';
    font-style: normal;
    display: flex;
    flex-direction: row;
    //justify-content: space-between;
    padding: 24px;
    background: #F4F5F7;
    border-radius: 6px;
    margin: 24px 0px;

    .base-box-item {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .item-num {
        font-weight: 500;
        font-size: 22px;
        line-height: 39px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FF6026;
        padding-bottom: 8px;
      }

      .item-desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height */
        text-align: center;
        color: #999999;
      }
    }
  }
}

.send-phone-box {
  margin-top: 40px;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: center;

  .send-phone-action {
    border: 1px solid #FF6026;
    width: 100%;
    height: 49px;
    background: #FF6026;
    font-weight: 500;
    font-size: 18px;
    line-height: 25.2px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    //padding: 7px 12px;
    border-radius: 5px;
  }
}

img.location {
  width: 12px;
  margin-right: 4px;
}

.base-left-title {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
  display: flex

}

.base-right-desc {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #131212;
  padding-left: 8px;
}

::v-deep .ant-descriptions-row > th, .ant-descriptions-row > td {
  padding: 8px 12px;
  width: 200px;
}

::v-deep .ant-descriptions-item-content {
  padding: 8px 12px;
}

.qrCodeTitle {
  /* 王先生 | 市场部 */
  height: 34px;
  width: 100%;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height */

  color: #131212;
  text-align: center;

  /* Inside auto layout */
  text-align: center;
  margin-bottom: 4px;
}

.qrCodePhone {
  /* 138 8888 8888 */

  width: 100%;
  height: 42px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  /* identical to box height */

  /* 品牌主题色 */
  color: #FF6026;
  text-align: center;
  margin-bottom: 16px;

}

.call {
  /* 扫码拨号 */

  width: 100%;
  height: 22px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #999999;
  margin-top: 16px;
}

.product-img {
  width: 270px;
  height: 202.5px;
  background: #D9D9D9;
  border-radius: 6px;

}

.product-name {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  color: #131212;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-top: 6px;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.product-type {
  /* 常规/正文2 body_sm_regular */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  display: inline-block;
  align-items: center;
  color: #536078;
  padding: 0px 4px;
  background: #F4F6F8;
  margin-top: 6px;
  border-radius: 3px;
  gap: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}


.page-area {
  text-align: center;
  margin-top: 25px;

  ::v-deep .ant-pagination-disabled {
    .ant-pagination-item-link {
      border-color: #D9D9D9;
      background: #EFEFEF;
      color: #BFBFBF;
    }
  }
}

::v-deep .swiper-container {
  position: unset;
}
.case-container-min {
  display: flex;
  justify-content: center !important;
}

::v-deep .case-container-min .swiper-wrapper {

  display: flex;
  justify-content: center !important;
}
::v-deep .product-swiper[data-v-508c5069] {
  margin-top: 0px !important;
  width: 100% !important;
}
.close-case {
  width: 30px;
  height: 30px;
  z-index: 700;

}

.case-desc {
  position: absolute;
  width: 720px;
  height: 120px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  border-radius: 0px 0px 12px 12px;
}

.case-title {

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* 白色 */
  color: #FFFFFF;
}

.case-caseDesc {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
</style>
