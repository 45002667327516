<template>
  <DrawerView :visible="visible" :width="800" :title="'散件所需数量'" show-slot-title @cancel="handleCancel">
    <div class="priceDetails">
      <a-form-model
        :class="'form-cover1 form-cover'"
        ref="basicForm"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :colon="false"
      >
        <div class="card-info-box" style="padding: 0px 24px 0px 0px">
          <a-row :gutter="26" class="basicInfo-form-item">
            <a-col :span="26">
              <a-form-model-item
                label="询价方式"
                prop="enquiryType" :rules="{ required: true, message: '请选择询价方式' }"
                style="display: flex"
              >
                <a-radio-group v-model="form.enquiryType" style="padding-left: 16px">
                  <a-radio :value="2">
                    阶梯数量询价
                  </a-radio>
                  <a-radio :value="3">
                    成本询价
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-if="form.enquiryType==2">
            <a-col style="text-align: left">
              <a-button style="margin-bottom: 16px ;background-color: #FF60261A;color: #FF6026;font-weight: bold"
                        type="primary" @click="toAdd">
                <a-icon type="plus"></a-icon>
                添加数量范围
              </a-button>
            </a-col>
          </a-row>
        </div>
        <div v-if="form.enquiryType==2">
          <div v-for="(item,index) in form.enquiryLadders">
            <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
              <h2 class="RequirementConfirmation-title">
                <span class="title">阶梯{{ index + 1 }}</span>
                <a class="action">
                </a>
                <div class="title" style="right: 0px;position: absolute;color: #FF6026;font-weight: 400"
                     @click="onDeleteLadder(item,index)"> 删除
                </div>
              </h2>
              <div class="card-info-box quote-mode-box" style="padding: 0px 24px 0px 0px">
                <a-row :gutter="26" class="basicInfo-form-item">
                  <a-col :span="26">
                    <a-form-model-item
                      label="选择数量范围"
                      :rules="{ required: true, message: '请选择数量范围' }"
                      style="display: flex;flex-direction: row"
                    >
                      <div style="display: flex;flex-direction: row;gap: 0px 16px;align-items: center">
                        <a-input-number placeholder="请输入" v-model="item.minCount" style="width: 120px;" type="number"></a-input-number>
                        <j-dictSelect
                          v-model="item.minCompare"
                          :dictData="symbolData"
                          placeholder="符号"
                          style="width: 120px; height: 38px"
                          :isDynamic="false"
                          @change="handleChangeA($event, item, index)"
                        />
                        <div class="num-style">“数量”</div>
                        <j-dictSelect
                          v-model="item.maxCompare"
                          :dictData="symbolData"
                          placeholder="符号"
                          style="width: 120px; height: 38px"
                          :isDynamic="false"
                          @change="handleChangeB($event, item, index)"
                        />
                        <a-input-number placeholder="请输入" v-model="item.maxCount" style="width: 120px;" type="number"></a-input-number>
                      </div>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="26">
                    <a-form-model-item
                      label="目标单价/元"
                      prop="unitPrice"
                      style="display: flex;flex-direction: row"
                    >
                      <a-input-number  placeholder="请输入" v-model="item.unitPrice" style="width: 320px;" ></a-input-number >
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </div>

        <div v-if="form.enquiryType==3">
          <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
            <h2 class="RequirementConfirmation-title">
              <span class="title">其他</span>
              <a class="action">
              </a>
            </h2>
            <div class="card-info-box quote-mode-box" style="padding: 0px 24px 0px 0px">
              <a-row :gutter="26" class="basicInfo-form-item">
                <a-col :span="26">
                  <a-form-model-item label="成本价构成" style="display: flex;align-items: center"
                                     prop=""
                                     :rules="{ required: true, message: '请选择' }"
                  >
                    <checkboxSearch v-model="form.enquiryOrigin.compose" @change="onEnquiryOriginChange"
                                    :indeterminate='true' :options="composeList" :showAll="false"
                                    :needLoad="false" />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="26" class="basicInfo-form-item">
                <a-col :span="26">
                  <a-form-model-item
                    label="目标单价/元"
                    prop="price"
                    style="display: flex;flex-direction: row"
                  >
                    <a-input-number placeholder="请输入" style="width: 320px;" v-model="form.enquiryOrigin.price" ></a-input-number>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </a-form-model>
    </div>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="submit" :loading="loading" type="primary">确定</a-button>
    </template>
  </DrawerView>
</template>
<script>
import { defineComponent } from 'vue'
import DrawerView from '@/components/plugins/drawerView.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import { getAction, postAction } from '@/api/manage'
import uploadFile from '@/components/plugins/uploadFile.vue'
import UploadDrag from '@/components/plugins/uploadDrag.vue'
import { mapGetters } from 'vuex'
import ZPagination from '@/components/plugins/ZPagination.vue'
import ViewContract from '@/views/ContractManagement/modules/viewContract.vue'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'

export default defineComponent({
  components: { checkboxSearch, ViewContract, UploadDrag, uploadFile, JDictSelect, DrawerView, ZPagination }
  ,
  data() {
    return {
      form: { enquiryType: 2, enquiryLadders: [], enquiryOrigin: {} },
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      formBaseInfo: {},
      formFile: {},
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      },
      orderDataSourceModel: [{
        orderNo: '2312312',
        orderName: '12312312',
        orderType: '预付全款',
        compayName: '上海我是供应有限公司',
        payable: '19890.00'
      }],
      visibleDel: false,
      visible: false,
      uploadVnioicevisible: false,
      invoiceData: {},
      targetVoidId: null,
      loading: false,
      invoiceFileList: [],
      symbolData: [{
        dictKey: 1,
        dictValue: '<'
      }, {
        dictKey: 2,
        dictValue: '≤'
      }],

      composeList: [
        {
          dictKey: '原材料费',
          dictValue: '原材料费'
        },
        {
          dictKey: '工序费',
          dictValue: '工序费'
        }, {
          dictKey: '管理费',
          dictValue: '管理费'
        }, {
          dictKey: '运费',
          dictValue: '运费'
        }, {
          dictKey: '税费',
          dictValue: '税费'
        }
      ],
      formIndex: null,
      formList: [],

    }
  },
  created() {

  },
  methods: {
    ...mapGetters(['dataSource']),
    handleOpen(item, index) {
      console.log(item)
      let items=JSON.parse(JSON.stringify(item))
      this.formList = items
      this.formIndex = index
      this.visible = true

      this.form.enquiryLadders = []
      this.form.enquiryOrigin = {}
      this.composeList.map(item => {
        item.checked = true
      })

      if (this.formList[this.formIndex].enquiryLadders != null && this.formList[this.formIndex].enquiryLadders.length > 0) {
        this.form.enquiryLadders = this.formList[this.formIndex].enquiryLadders
      } else {
        this.form.enquiryLadders.push({})
      }
      if (this.formList[this.formIndex].enquiryOrigin != null && this.formList[this.formIndex].enquiryOrigin != undefined) {
        this.form.enquiryOrigin = this.formList[this.formIndex].enquiryOrigin
        if (this.form.enquiryOrigin.compose) {
          let composeListSplit = this.form.enquiryOrigin.compose.split(',')
          this.composeList.filter(item => composeListSplit.includes(item)).map(item => {
            item.checked = true
          })
        }
      } else {
        this.enquiryOrigin = {}
      }

      this.$forceUpdate()
      // this.handleLoadData()
    },
    handleCancel() {
      this.visible = false
    }, saleInfoDelete() {
      this.visibleDel = true
    },
    handleChangePage({ currentPage, pageSize }) {
      this.pageNation.pageNum = currentPage
      this.pageNation.pageSize = pageSize
      this.handleLoadData()
    },
    handleLoadData() {
      getAction('/v1/invoice/invoiceDetail', {
        id: this.targetVoidId
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.invoiceData = data
        } else {
          this.$message.warning(message)
        }
      })
    },
    submit() {
      // 阶梯
      if (this.form.enquiryType == 2) {
        //为空判断

        let tip2 = null
        this.form.enquiryLadders.map((item, index) => {
          if (tip2 == null && (item.minCount == null || item.maxCount == null)) {
            tip2 = '阶梯 ' + (index + 1) + '  数量必填'
          }
        })
        if (tip2 != null) {
          return this.$message.warning(tip2)
        }
        let tip = null
        this.form.enquiryLadders.map((item, index) => {
          if (tip == null && (item.minCompare == null || item.maxCompare == null)) {
            tip = '阶梯 ' + (index + 1) + '  符号必选'
          }
        })
        if (tip != null) {
          return this.$message.warning(tip)
        }
        //大小判断
        let tip3 = null
        this.form.enquiryLadders.map((item, index) => {
          if (tip3 == null && parseInt(item.minCount) > parseInt(item.maxCount)) {
            tip3 = '阶梯 ' + (index + 1) + '  数量最小值不能大于最大值'
          } else if (tip3 == null && (item.minCount === item.maxCount)) {
            tip3 = '阶梯 ' + (index + 1) + '  请输入有效数量范围'
          }
        })
        if (tip3 != null) {
          return this.$message.warning(tip3)
        }

        //检查范围
        let ladders = []
        this.form.enquiryLadders.map(item => {
          let minCount = 0
          let maxCount = 0
          if (item.minCompare == '<') {
            minCount = parseInt(item.minCount)
          } else {
            minCount = parseInt(item.minCount)
          }
          if (item.maxCompare == '<') {
            maxCount = parseInt(item.maxCount)
          } else {
            maxCount = parseInt(item.maxCount)
          }

          ladders.push({
            minCount,
            maxCount
          })
        })

        const result = this.isContinuousIntervals(ladders)
        if (!result) {
          return this.$message.warning('阶梯数量范围格式不正确')
        }
        let step = 1
        for (let i = 0; i < this.form.enquiryLadders.length - 1; i++) {
          const currentInterval = this.form.enquiryLadders[i]
          const nextInterval = this.form.enquiryLadders[i + 1]
          // 判断当前区间的结束值是否等于下一个区间的起始值
          if (currentInterval.maxCompare == nextInterval.minCompare) {
            if (step == 1) {
              step = i + 2
            }
          }
        }
        if (step > 1) {
          return this.$message.warning('阶梯' + step + '第一个符号不能跟阶梯' + (step - 1) + '第二个符合相同')
        }
      }

      if (this.form.enquiryType == 3) {
        let costList = this.composeList.filter(item => item.checked == true)
        if (costList.length == this.composeList.length) {
          this.form.enquiryOrigin.compose = this.composeList.map(item => item.name).join(',')
          console.log('this.enquiryOrigin.compose'+this.form.enquiryOrigin.compose)
        }

        if (this.form.enquiryOrigin.compose==null || this.form.enquiryOrigin.compose==undefined) {
          return this.$message.warning('请选择成本构成')
        }

        if (!this.form.enquiryOrigin.compose.includes('原材料费')) {
          return this.$message.warning('请勾选原材料费')
        }
      }

      this.$emit('ok', this.form)
      console.log(this.form.enquiryType)
      this.visible = false

    }, toAdd() {
      this.form.enquiryLadders.push({})
    }, onDeleteLadder(item, index) {
      this.form.enquiryLadders.splice(index, 1)
    }, handleChangeA(e, item, index) {
      let label = null,
        value = null
      if (e) {
        value = e
        label = this.symbolData.find((i) => i.dictKey == e).dictValue
      }
      this.form.enquiryLadders[index].minCompare = label
      console.log(label)
    }, handleChangeB(e, item, index) {
      let label = null,
        value = null
      if (e) {
        value = e
        label = this.symbolData.find((i) => i.dictKey == e).dictValue
      }
      this.form.enquiryLadders[index].maxCompare = label
      console.log(label)
    },
    isContinuousIntervals(intervals) {
      for (let i = 0; i < intervals.length - 1; i++) {
        const currentInterval = intervals[i]
        const nextInterval = intervals[i + 1]
        // 判断当前区间的结束值是否等于下一个区间的起始值
        if (currentInterval.maxCount !== nextInterval.minCount) {
          return false
        }
      }
      return true
    }, onEnquiryOriginChange(e) {
      console.log(e)
      this.form.enquiryOrigin.compose = e
      let costList = this.form.enquiryOrigin.compose.split(',')
      this.composeList.filter(item => costList.includes(item.name)).map(item => {
        item.checked = true
      })
      this.composeList.filter(item => !costList.includes(item.name)).map(item => {
        item.checked = false
      })
    }

  }
})

</script>
<style lang="less" scoped>
.invoiceDetails {
  box-sizing: border-box;
  background-color: #ffffff;
}

.RequirementConfirmation {
  .header {
    &-title {
      color: #090b12;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 15px;

      .copy {
        font-size: 13px;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }

  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    position: relative;

    &:before {
      background: #FF6026;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 14px;
      color: #8d8e99;
    }
  }

  &-content {
    &-item {
      width: 100%;
      background: #fff;
      position: relative;

      .edit-btn {
        position: absolute;
        top: 4px;
        right: -15px;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep .ant-form-item-label {
    line-height: 22px;

    label {
      color: #626161 !important;
    }
  }

  ::v-deep .ant-form-item-control {
    line-height: 22px;
    color: #131212;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subTitle {
    width: calc(100% - 40px);
    font-size: 15px;
    color: #FF6026;
    padding-bottom: 8px;
    border-bottom: 1px solid #efefef;
    margin: 0 auto 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag-title {
      padding: 2px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background: #fff2ea;
      border-radius: 2px;
    }

    img {
      width: 15px;
      margin-right: 4px;
    }

    span {
      line-height: 22px;
      font-size: 14px;
    }
  }

  .basicInfo-form {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 18px 0 4px;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &-item {
      padding: 0 20px;
    }
  }

  ::v-deep .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-btn-link[disabled] img {
    filter: grayscale(100%);
  }

  .price-title {
    width: 107px;
    margin-top: 8px;
  }

  .process-content {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;

    ::v-deep .ant-timeline-item-tail {
      border: 0.5px dashed #dcdcdc;
    }

    ::v-deep .ant-timeline-item-head {
      width: 5px;
      height: 5px;
      background: #d7d7db;
      border: none;
      left: 2px;
      top: 3px;
    }

    .content {
      padding-top: 5px;
      color: #000000;
      font-size: 14px;
      line-height: 20px;
    }

    .time {
      font-size: 14px;
      line-height: 20px;
      color: #8d8e99;
    }
  }
}

.ant-descriptions {
  // background-color: #f7f8fa;
  // padding: 16px 25px 0;
  // border-radius: 8px;

  &.tag_2 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

::v-deep .ant-descriptions-item-label {
  color: #595959;
}

::v-deep .ant-descriptions-item-content {
  color: #131212;
}

::v-deep .ant-radio-disabled + span {
  color: #262626;
}

.card-view {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px 25px;

  &-item {
    width: calc((100% - 50px) / 3);
    height: 144px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px dashed #e8e9eb;
    padding: 15px;
    display: flex;
    position: relative;

    .cover {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        & + .option-area {
          opacity: 1;
          z-index: 10;
        }
      }
    }

    .option-area {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.65);
      position: absolute;
      left: 15px;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      z-index: -1;

      &:hover {
        opacity: 1;
        z-index: 10;
      }

      img {
        height: 16px;
        cursor: pointer;
      }

      .split-line {
        width: 1px;
        height: 14px;
        background-color: #fff;
        margin: 0 15px;
      }
    }
  }

  .info-card {
    &.inside {
      flex: 1 0 0;
      min-width: 0;
    }

    .sub_title {
      font-size: 14px;
      font-weight: 400;
      color: #131212;
      line-height: 22px;
      margin-bottom: 8px;
      overflow: hidden;
      .text-ellipsis-multi(2);
    }

    div.labels {
      font-size: 12px;
      color: #8d8e99;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.desc-title {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 12px;
  margin-bottom: 15px;

  span.title {
    font-size: 14px;
    color: #131212;
  }
}

div.file-card {
  width: max-content;
  height: 64px;
  background: #fff;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  margin-bottom: 8px;

  &-title {
    margin-bottom: 15px;

    img.icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .fileName {
      color: #1d2129;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-size {
    font-weight: 400;
    color: #8d8e99;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    padding-left: 22px;
  }
}

.coverBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.last-label {
  width: 100%;
  height: 102px;
  background-image: url('~@/assets/longerBG.png');
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  .ant-form-item {
    margin-bottom: 0;
  }

  ::v-deep .ant-input {
    border-color: #ff9a57;
  }

  ::v-deep .ant-input-suffix {
    color: #131212;
  }

  .moneyIcon {
    position: absolute;
    bottom: 0;
    right: 19px;
  }
}

.numberInput {
  width: 320px;
  border-color: #ff7d2f;
  height: 38px;
  line-height: 38px;

  ::v-deep .ant-input-number-input {
    font-size: 18px;
    color: #131212;
    font-weight: 500;
  }
}


@import '~@/styles/form.less';
@import '~@/styles/orderDetail.less';
.action {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 14px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  img {
    width: 20px;
    margin-right: 4px;
  }
}

::v-deep .ant-form-item-label {
  width: 80px !important;
  display: flex;
  text-align: right !important;
  justify-content: flex-end;
}

.sale-table {
  .quote-table {
    padding: 0 15px;
    overflow-x: auto;

    .ant-table-wrapper {
      width: 1056px;
    }

    ::v-deep .ant-table-thead > tr > th {
      background: #F4F5F7;
    }

    ::v-deep .ant-table-footer {
      height: 48px;
      padding: 0;

      .footer-info-bar {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 16px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
        }

        .total {
          height: 100%;
          border-left: 1px solid #E8E9EB;
          width: 484px;
          line-height: 46px;
          padding-left: 16px;
          color: #FF6026;
          font-size: 16px;
        }
      }
    }
  }
}

::v-deep .ant-table-footer {
  //height: 56px !important;
  padding: 0px !important;
}

.orderno-tag {
  //padding: 9px 4px 9px 12px;
  //min-width: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 10px;
  background: #E8E9EB;
  border-radius: 3px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  margin-right: 4px;
}

.saleName {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #264AFF;
  /* 状态标签 */
  display: flex;
  flex-direction: row;
  //justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  background: #E2E7FB;
  border-radius: 3px;

}

.saleTitleGroup {
  display: flex;

}

.saleTitle, .salePart {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.85);
  background: #F4F5F7;
  border: 1px solid #E8E9EB;
  width: 100%;
  padding: 10px 16px;

  font-family: 'PingFang SC';
  font-style: normal;

  font-size: 14px;
  line-height: 150%;
  /* 文字色/标题+主要 */
  color: rgba(0, 0, 0, 0.85);
  border-right: 0px;
}

.saleTitle {
  font-weight: bold;
}

.salePart {
  background-color: #ffffff;
  border-top: 0px;
  border-right: 0px;
}

.sale-table {
  border: 1px solid #E8E9EB;
  margin-top: 24px;
}

.openingInvocie {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding-right: 16px;
}

.info-card {
  font-size: 16px;
  color: #131212;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.uploadCover {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .sale-table[data-v-cd4a0cfc] {
  border-radius: 4px;
}

::v-deep .ant-table table {
  border-radius: 4px;
}

::v-deep .quote-mode-box [data-v-48924415] .ant-form-item-label {
  width: 108px !important;
}

::v-deep .quote-mode1-box [data-v-48924415] .ant-form-item-label {
  width: 95px !important;
}

.num-style {

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #1681FF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: rgba(22, 129, 255, 0.1);
  border-radius: 8px;
  width: 61px;
  min-width: 61px;
  height: 28px;
}
::v-deep .ant-divider ,.ant-divider-vertical{
  width: auto !important;
}
</style>

