<!--
 * @Description: 报价列表（生产商）
 * @Author: zhang zhen
 * @Date: 2023-07-28 16:52:51
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-20 17:40:56
 * @FilePath: /page-sass/src/views/quotePriceManagement/quotePriceList.vue
-->
<template>
  <div class="infoCard">
    <div class="card-info-box">
      <div class="searchInfo">
        <a-input
          v-model="queryParams.title"
          placeholder="请输入需求单号、需求名称或其他关键词"
          style="width: 480px; height: 38px; line-height: 38px"
        />
        <div class="option-area">
          <a-button type="primary" @click="handleSearch" style="width: 81px">查询</a-button>
          <a-button @click="reaLoad" style="width: 81px">重置</a-button>
        </div>
      </div>
      <a-form-model
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="left"
        style="margin-top: 8px"
        :colon="false"
      >
        <a-form-model-item label="报价类型">
          <checkboxSearch v-model="queryParams.quoteSource" :options="quoteOptions" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="报价状态">
          <checkboxSearch v-model="queryParams.status" :options="mainOptions" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="合作状态">
          <checkboxSearch v-model="queryParams.bizStatus" :options="newOptions" :needLoad="false" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="card-info-box">
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="dataSource"
        :pagination="false"
        style="margin-bottom: 16px"
        :scroll="{ x: 'max-content' }"
        rowKey="index"
      >
        <template slot="quoteSource" slot-scope="text, record">
          {{ text == 1 ? '主动报价' : '邀请报价' }}
        </template>
        <template slot="quoteDeadline" slot-scope="text, record">
          <div>
            {{ (text && text.substring(0, 10)) || '' }}
          </div>
        </template>
        <template slot="statusLabel" slot-scope="text, record">
          <span class="table-status-btn" :class="quoteStyle[record.status + '']">
            {{ text || '' }}
          </span>
        </template>
        <template slot="bizStatus" slot-scope="text, record">
          <span class="table-status-btn" :class="statusDictStyle[record.bizStatus]">
            {{ statusDictList[text] || '' }}
          </span>
        </template>
        <span slot="bizStatusTag">
          合作状态
          <a-tooltip placement="bottom">
             <template slot="title" style="width: 335px !important;">
待开始：等待采购商发起订单<br/>
进行中：进入订单生产流程，请在 '订单管理'中操作<br/>
已完成：订单已完成
    </template>
            <a-icon type="exclamation-circle" theme="filled" :rotate=180 />
          </a-tooltip>
        </span>
        <template slot="operation" slot-scope="text, record">
          <a @click="handleQuote(record)" v-if="record.status == '3'"><a-icon type="inbox" /> 立即报价</a>
          <a @click="handleQuote(record)" v-if="record.status == '0' || record.status == '-1'"
            ><a-icon type="retweet" /> 重新报价</a
          >
          <a-divider type="vertical" v-if="record.status == '-1'" />
          <a
            @click="handleSwitchTab(record)"
            v-if="record.status == '1' || record.status == '-1' || record.status == '2'"
            ><a-icon type="eye" /> 查看报价</a
          >
          <template v-if="record.showContractButtonSupplier">
            <a-divider type="vertical" />
            <a @click="handleContract(record)"><a-icon type="highlight" /> 签署合作合同</a>
          </template>
          <template v-if="record.status == '0'">
            <a-divider type="vertical" />
            <a @click="handleCancel(record.quoteId)"><a-icon type="minus-circle" /> 取消报价</a>
          </template>
          <template v-if="record.orderNo">
            <a-divider type="vertical" />
            <a @click="handleViewOrder(record)"><a-icon type="eye" /> 查看订单</a>
          </template>
        </template>
      </a-table>
      <ZPagination
        :total="pageNation.total"
        :current="pageNation.pageNum"
        :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage"
      />
    </div>
    <input ref="fileUploaderRef" type="file" class="fileUploader" @change="handleFileUpload" />
    <SplitOrderInfo ref="splitOrderInfoRef" @ok="reaLoad" />
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters } from 'vuex'
import { postAction, getAction, uploadAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import SplitOrderInfo from './modules/splitOrderInfo.vue'
export default {
  name: 'quotePriceList',
  mixins: [ListMixin],
  components: {
    ZPagination,
    EmptyArea,
    checkboxSearch,
    SplitOrderInfo,
  },

  data() {
    return {
      showMore: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      queryParams: {
        status: undefined,
      },
      rangeDate: [],
      columns: [
        {
          title: '需求编号',
          dataIndex: 'purchaseNo',
          key: 'purchaseNo',
          width: 220,
        },
        {
          title: '需求名称',
          dataIndex: 'purchaseTitle',
          key: 'purchaseTitle',
          ellipsis: true,
        },
        {
          title: '采购方',
          dataIndex: 'purchaseBusinessName',
          key: 'purchaseBusinessName',
          ellipsis: true,
        },
        {
          title: '报价时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 180,
        },
        {
          title: '报价状态',
          dataIndex: 'statusLabel',
          key: 'statusLabel',
          width: 110,
          scopedSlots: { customRender: 'statusLabel' },
        },
        {
          // title: '合作状态',
          slots: { title: 'bizStatusTag' },
          dataIndex: 'bizStatus',
          key: 'bizStatus',
          width: 110,
          scopedSlots: { customRender: 'bizStatus' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      quoteOptions: [
        {
          dictKey: '1',
          dictValue: '主动报价',
        },
        {
          dictKey: '2',
          dictValue: '邀请报价',
        },
      ],
      mainOptions: [
        {
          dictKey: '3',
          dictValue: '待报价',
        },
        {
          dictKey: '0',
          dictValue: '已报价',
        },
        {
          dictKey: '1',
          dictValue: '中标',
        },
        {
          dictKey: '2',
          dictValue: '未中标',
        },
        {
          dictKey: '-1',
          dictValue: '已取消',
        },
      ],
      newOptions: [
        {
          dictKey: '0',
          dictValue: '待开始',
        },
        {
          dictKey: '1',
          dictValue: '进行中',
        },
        {
          dictKey: '3',
          dictValue: '已完成',
        },
      ],
      activeInfo: {},
      requestType: 'post',
      statusDictList: {
        0: '待开始',
        1: '进行中',
        3: '已完成',
      },
      statusDictStyle: {
        0: 'status_8',
        1: 'status_4',
        3: 'status_10',
      },
      quoteStyle: {
        0: 'status_4',
        1: 'status_6',
        2: 'status_2',
        3: 'status_8',
        '-1': 'status_10',
      },
      url: {
        list: '/quote/list', //0827
      },
    }
  },
  created() {
    this.handleLoadData(1)
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleJump(url) {
      window.open(url)
    },
    /* 查看订单 */
    handleViewOrder(e) {
      this.$router.push({
        path: '/orderManagement',
        query: {
          orderNo: e.orderNo,
        },
      })
    },
    handleContract(e) {
      this.$router.push({
        path: '/ContractManagement',
      })
    },
    handleFileUpload(e) {
      let selectedFile = event.target.files[0]
      let formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('dir', 'images')
      uploadAction('/file/images/upload', formData).then((res) => {
        const { success, message, data } = res
        if (success) {
          // 触发签单接口
          postAction('/v1/contract/framework/offline/sign', {
            contractFile: data.url,
            contractNo: this.activeInfo.contractNo || '',
          }).then((res) => {
            const { success, message } = res
            if (success) {
              this.reaLoad()
            } else {
              this.$message.error(message)
            }
          })
        } else {
          this.$message.error(message)
        }
      })
    },
    handleUploadContract(item) {
      this.activeInfo = {
        purchaseId: item.purchaseId || '',
        contractNo: item.contractNo || '',
      }
      const uploader = this.$refs.fileUploaderRef
      uploader.value = ''
      uploader.click()
    },
    handleSearch() {
      if (this.rangeDate.length) {
        const [submitStartDate, submitEndDate] = this.rangeDate
        this.queryParams['submitStartDate'] = submitStartDate
        this.queryParams['submitEndDate'] = submitEndDate
      }
      this.handleLoadData(1)
    },
    handleConfirmSplit(item) {
      this.$refs.splitOrderInfoRef.handleShowOrder({ ...item })
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0,
        }
      }
      const { quoteSource, status, title, bizStatus } = this.queryParams
      let quoteSourceList = [],
        statusList = [],
        params = {}
      if (quoteSource) {
        quoteSourceList = quoteSource.split(',')
      }
      if (bizStatus) {
        params['bizStatus'] = bizStatus.split(',')
      }
      if (status) {
        statusList = status.split(',')
      }
      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      let pageParams = { ...this.pageNation }
      delete pageParams.total
      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : this.requestType == 'post' ? postAction : postQueryAction
      api(this.url.list, {
        title: title || '',
        status: statusList,
        quoteSource: quoteSourceList,
        pagesSize: pageSize,
        pageNum,
        ...params,
        ...this.sorter,
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            const { total, list } = data
            console.log(list)
            this.dataSource = list
            this.pageNation['total'] = total
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    /* 参与报价 */
    handleQuote(item) {
      this.$router.push(
        `/quotePriceManagement/quotePriceDetailsView?id=${item.purchaseId}&quoteSource=${item.quoteSource}&quoteId=${item.quoteId}`
      )
    },
    handleSwitchTab(item) {
      const { quoteId, purchaseId, quoteSource } = item
      // this.$router.push(
      //   `/quotePriceManagement/quotePriceInfoView?purchaseId=${purchaseId}&quoteId=${quoteId}&isDetail=1&businessId=${businessId}`
      // )
      this.$router.push(
        `/quotePriceManagement/quotePriceDetailsView?id=${purchaseId}&quoteSource=${quoteSource}&quoteId=${quoteId}&isView=2`
      )

    },
    reaLoad() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      }
      this.queryParams = {}
      this.rangeDate = []
      this.dataSource = []
      this.handleLoadData(1)
    },
    // 取消报价
    handleCancel(quoteId) {
      postAction(`/quote/cancel/${quoteId}`).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success('报价已取消')
          this.reaLoad()
        } else {
          this.$message.warning(message)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

// ::v-deep .ant-table-column-title {
//   font-size: 16px;
// }

::v-deep .ant-table-thead {
  tr {
    background: #f4f5f7 !important;

    th {
      border-bottom: none;
      font-size: 14px;
      font-weight: 600 !important;
      color: #131212;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    // &:not(:last-of-type)>td {
    //   border: none;
    // }

    & > td {
      border-bottom: 1px solid #efefef;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead > tr > th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    margin-left: 8px;
    .ant-btn {
      width: 97px;
      height: 38px;

      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}

@import '~@/styles/list.less';

::v-deep .ant-form-item-label {
  width: 67px !important;
}

.fileUploader {
  display: none;
}

</style>
<style>
.ant-tooltip-inner{
  width: 340px;
}
</style>
