<!--
 * @Description: 公共需求池
 * @Author: zhang zhen
 * @Date: 2023-02-15 14:08:56
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-08-15 19:56:03
 * @FilePath: /page-sass/src/views/requirement/requirementPool/index.vue
-->
<template>
  <div class="infoCard">
    <template v-if="isPass">
      <div class="card-info-box">
        <div class="searchInfo">
          <a-input v-model="queryParams.keyword" placeholder="请输入需求单号、需求名称或其他关键词"
            style="width: 480px; height: 38px; line-height: 38px;" />
          <div class="option-area">
            <a-button type="primary" @click="handleSearch" style="width: 81px;">查询</a-button>
            <a-button @click="reaLoad" style="width: 81px;">重置</a-button>
          </div>
        </div>
        <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left" style="margin-top: 8px;"
          :colon="false">
          <a-form-model-item label="需求类型">
            <checkboxSearch v-model="queryParams.purchaseTypes" dictCode="1043" />
          </a-form-model-item>
          <a-form-model-item label="包装材料">
            <checkboxSearch v-model="queryParams.wrapTypes" dictCode="1507" />
          </a-form-model-item>
          <a-form-model-item label="业务地区">
            <div class="area-block">
              <checkboxSearch class="left-choose" v-model="queryParams.cityCodes" :options="areaList"
                :needLoad="false" />
              <AreaDropPicker ref="AreaDropPickerRef" v-model="queryParams.cityCodes" />
            </div>
          </a-form-model-item>
          <a-form-model-item label="发布时间">
            <radioGroup v-model="publishDateScoped" :options="mainOptions" :needLoad="false" :needAll="false"
              @change="handleChangeRadio($event, 'publishDate')">
              <a-radio :value="-1">
                <a-range-picker v-model="publishDateRange" value-format="YYYY-MM-DD HH:mm:ss" separator="⇀" showTime
                  @change="handleChangeDate($event, 'publishDateScoped')" style="width: 310px;height: 26px;">
                  <span class="pickerDict">
                    <span v-if="publishDateRange.length">{{ publishDateRange[0] }} ~ {{ publishDateRange[1] }}</span>
                    <span class="placeholder" v-else>请选择</span>
                    <a-icon type="caret-down" style="margin-left: 8px;color: #999"></a-icon>
                  </span>
                </a-range-picker>
              </a-radio>
            </radioGroup>
          </a-form-model-item>
          <a-form-model-item label="截止报价时间">
            <radioGroup v-model="validityDateScoped" :options="otherOptions" :needLoad="false"
              @change="handleChangeRadio($event, 'validityDate')">
              <a-radio :value="-1">
                <a-range-picker v-model="validityDateRange" value-format="YYYY-MM-DD HH:mm:ss" separator="⇀" showTime
                  @change="handleChangeDate($event, 'validityDateScoped')" style="width: 310px;height: 26px;">
                  <span class="pickerDict">
                    <span v-if="validityDateRange.length">{{ validityDateRange[0] }} ~ {{ validityDateRange[1] }}</span>
                    <span class="placeholder" v-else>请选择</span>
                    <a-icon type="caret-down" style="margin-left: 8px;color:#999"></a-icon>
                  </span>
                </a-range-picker>
              </a-radio>
            </radioGroup>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="card-info-box last-box">
        <a-tabs v-model="queryParams.sortType" @change="handleChangeTab">
          <a-tab-pane key="TIME" tab="时间排序">
          </a-tab-pane>
          <a-tab-pane key="OTHER" tab="相关性排序">
          </a-tab-pane>
        </a-tabs>
        <EmptyArea v-if="!dataSource || !dataSource.length" />
        <div class="table-card-cover">
          <div class="table-card-cover-item" v-for="i in dataSource" :key="i.id">
            <div class="left-more-info">
              <!-- 新需求的标签 -->
              <div class="main-title">{{ i.purchaseTitle || '' }} <span class="newTag" v-if="i.newFlag">新</span></div>
              <div class="tags-list">
                <!-- v-for="row in i.leftTagList" :key="row" -->
                <div class="tags-list-item" v-if="i.businessType == 'BUSINESS'">
                  <span>
                    <a-tooltip slot="suffix" title="企业采购">
                      企业采购
                    </a-tooltip>
                  </span>
                </div>
                <div class="tags-list-item" v-else>
                  <span>
                    <a-tooltip slot="suffix" title="个人采购">
                      个人采购
                    </a-tooltip>
                  </span>
                </div>
                <div class="tags-list-item">
                  <span>
                    <a-tooltip slot="suffix" :title="i.purchaseTypeDictName || '-'">
                      {{ i.purchaseTypeDictName || '-' }}
                    </a-tooltip>
                  </span>
                </div>
<!--                <div class="tags-list-item">-->
<!--                  <span>-->
<!--                    <a-tooltip slot="suffix" :title="`年包材需求总额：${i.packagingCostTotalDictName || '-'}`">-->
<!--                      年包材需求总额：{{ i.packagingCostTotalDictName || '-' }}-->
<!--                    </a-tooltip>-->
<!--                  </span>-->
<!--                </div>-->
<!--                <div class="tags-list-item">-->
<!--                  <span>-->
<!--                    <a-tooltip slot="suffix" :title="`单次最小下单量：${i.minOrderCountDictName || '-'}`">-->
<!--                      单次最小下单量：{{ i.minOrderCountDictName || '-' }}-->
<!--                    </a-tooltip>-->
<!--                  </span>-->
<!--                </div>-->
              </div>
              <div class="counter">
                <span class="label">需求清单：</span>
                <span class="category"><template>{{ i.purchaseTypeDictName || '-' }};</template> 印刷方式：{{
      i.printProcessDictName || '-' }}、{{ i.remark || '' }}</span>
              </div>
            </div>
            <div class="money-info">
              <span class="num" v-if="i.priceBudget">{{ i.priceBudget | CreateUnit }}</span>
              <span class="num" style="color:#EE4261;" v-else>由生产商报价</span>
              <span class="label">预算金额</span>
            </div>
            <div class="money-info">
              <span class="num">{{ i.quotationDeadline && i.quotationDeadline.substring(0, 10) || '未填写' }}</span>
              <span class="label">截止报价时间</span>
            </div>
            <!-- 发布时间 -->
            <span class="createdTime">发布时间: {{ i.issuedDate && i.issuedDate.substring(0, 10) || '' }}</span>
            <div class="cover_btn">
              <a-button type="primary" style="height: 36px;margin-left: 32px;" @click="handleJoinPlan(i)">查看详情</a-button>
              <!-- <a-button type="primary" ghost style="height: 36px;margin-left: 32px;margin-top: 10px;color: #FF6026;" @click="handleCreateChat(i)">咨询TA</a-button> -->
            </div>
          </div>
        </div>
        <div class="page-bar">
          <ZPagination :total="pageNation.total" :current="pageNation.pageNum" :pageSize="pageNation.pageSize"
            @handlePageChange="handleChangePage" />
        </div>
      </div>
    </template>
    <template v-else>
      <a-result title="请您补充完善资料后,可查看更多需求内容">
        <template #icon>
          <img src="/emptyStatus.png" alt="" class="icon">
        </template>
        <template #extra>
          <a-button type="primary" style="width: 92px" @click="handleChangeForm">
            去完善
          </a-button>
        </template>
      </a-result>
    </template>
    <!-- 提示语句 -->
    <a-modal title="温馨提示" :visible="visible" centered @ok="handleQuote" @cancel="handleCancel">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>确认参与当前选中需求报价或议价?</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import { postAction, getAction, postQueryAction } from '@/api/manage'
import { mapGetters, mapActions } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import AreaDropPicker from '@/components/plugins/areaDropPicker.vue'
import { getDateRange, getMonthDateRange } from '@/util/utils'
import ChatMixin from '@/mixins/chatMixins'

export default {
  name: 'RequirementPool',
  mixins: [ChatMixin],
  components: {
    ZPagination,
    EmptyArea,
    checkboxSearch,
    radioGroup,
    AreaDropPicker
  },
  data() {
    return {
      publishDateScoped: '3',
      validityDateScoped: '',
      publishDateRange: [],
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      visible: false,
      loading: false,
      showMore: false,
      validityDateRange: [],
      queryParams: {
        sortType: 'TIME',
        purchaseTypes: '',
        wrapTypes: '',
        cityCodes: ''
      },
      showMoreArea: false,
      targetPurchaseId: null,
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      dataSource: [],
      mainOptions: [
        {
          dictKey: '0',
          dictValue: '当日'
        },
        {
          dictKey: '1',
          dictValue: '近7日'
        },
        {
          dictKey: '2',
          dictValue: '近30天'
        },
        {
          dictKey: '3',
          dictValue: '近90天'
        },
        {
          dictKey: '4',
          dictValue: '近半年'
        },
        {
          dictKey: '5',
          dictValue: '近一年'
        }
      ],
      otherOptions: [
        {
          dictKey: '0',
          dictValue: '本月'
        },
        {
          dictKey: '1',
          dictValue: '下月'
        },
        {
          dictKey: '2',
          dictValue: '两月后'
        },
      ],
      url: {
        list: '/v2/purchases/query',
      },
      isPass: true,
      allCityList: [],
      areaList: [],
    }
  },
  created() {
    this.handleLoad() // 加载市
    this.handleLoadData(1)
  },
  mounted() {
    this.isPass = this.setUserInfo().status == 1
  },
  methods: {
    ...mapActions(['changeInfoCount']),
    handleCleanCount() {
      this.changeInfoCount({
        requirementPoolCount: 0
      })
    },
    handleSwitchArea(status) {
      this.showMoreArea = status;
      // console.log(status)
      this.$refs.AreaDropPickerRef.handleSwitch(status);
      // this.areaList = status ? this.allCityList : this.allCityList.slice(0, 9)
    },
    handleCreateChat(item) {
      postQueryAction('/business/queryByBusinessId', {
            businessId: item.businessId
          }).then(res => {
            const { success, data } = res
            if (success) {
              const { businessName, userId } = data;
              this.handleChat({ businessName, userId })
            }
          })

    },
    handleLoad() {
      getAction('area/queryByLevel', {
        level: 2
      }).then(res => {
        const { data, success } = res;
        if (success) {
          this.allCityList = data.map(i => ({ dictKey: i.regionId, dictValue: i.regionName }));
          this.areaList = this.allCityList.slice(0, 9)
        }
      })
    },
    customExpandIcon(props) {
      const { expanded, onExpand, record } = props
      return props.expanded ? (
        <a-icon type="caret-down" onClick={(e) => onExpand(record, e)} />
      ) : (
        <a-icon type="caret-right" onClick={(e) => onExpand(record, e)} style="opacity: 0.25" />
      )
    },
    ...mapGetters(['setUserInfo']),
    handleSwitchNav({ purchaseId, purchaseTitle, purchaseNo, businesStatus, status }) {
      this.targetPurchaseId = purchaseId
      this.handleQuote()
    },
    handleChangeTab() {
      this.handleSearch();
    },
    handleChangePage({ pageNum, pageSize }) {
      console.log(pageNum, pageSize)
      this.pageNation.pageNum = pageNum
      this.pageNation.pageSize = pageSize
      this.handleLoadData()
    },
    /* 搜索 */
    handleSearch() {
      this.handleLoadData(1)
    },
    handleChangeForm() {
      this.$router.push({
        path: '/businessManagement/companyInfo'
      })
    },
    reaLoad() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      }
      this.queryParams = { sortType: 'TIME' }
      this.publishDateRange = []
      this.publishDateScoped = '3'
      this.validityDateScoped = ''
      this.validityDateRange = []
      this.dataSource = []
      this.handleLoadData(1)
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${key}Range`] = [];
      }
    },
    handleLoadData() {
      const { pageNum, pageSize } = this.pageNation
      this.loading = true
      const { purchaseTypes, wrapTypes, cityCodes, sortType, keyword } = this.queryParams;
      let purchaseTypesList = [], wrapTypesList = [], cityCodesList = [], params = {
        keyword,
        publishEndTime: "",
        publishStartTime: "",
        validityDateEnd: "",
        validityDateStart: "",
      };
      if (purchaseTypes) {
        purchaseTypesList = purchaseTypes.split(',');
      }
      if (wrapTypes) {
        wrapTypesList = wrapTypes.split(',');
      }
      if (cityCodes) {
        cityCodesList = cityCodes.split(',');
      }
      if (this.publishDateScoped != '' && this.publishDateScoped != -1) {
        if (this.publishDateScoped == '6') {
          let lastYear = new Date().getFullYear() - 1;
          params = { ...params, publishStartTime: `${lastYear}-01-01 00:00:00`, publishEndTime: `${lastYear}-12-31 23:59:59` }
        } else {
          const [publishStartTime, publishEndTime] = getDateRange(this.publishDateScoped)
          params = { ...params, publishStartTime, publishEndTime }
        }

      } else if (this.publishDateScoped != -1 && this.publishDateRange.length) {
        const [publishStartTime, publishEndTime] = this.publishDateRange;
        params = { ...params, publishStartTime, publishEndTime }
      }

      if (this.validityDateScoped != '' && this.validityDateScoped != -1) {
        const [validityDateStart, validityDateEnd] = getMonthDateRange(this.validityDateScoped)
        params = { ...params, validityDateStart, validityDateEnd }
      } else if (this.validityDateScoped == -1 && this.validityDateRange.length) {
        const [validityDateStart, validityDateEnd] = this.validityDateRange;
        params = { ...params, validityDateStart, validityDateEnd }
      }

      postAction(this.url.list, {
        businessId: this.setUserInfo().businessId,
        pageNum,
        pagesSize: pageSize,
        sortType,
        purchaseTypes: purchaseTypesList,
        wrapTypes: wrapTypesList,
        cityCodes: cityCodesList,
        ...params
      }).then((res) => {
        const { success, data } = res
        this.loading = false
        if (success) {
          const { total, list } = data
          this.pageNation.total = total
          this.dataSource = list
        }
      })
    },
    /* 参与报价 */
    handleQuote() {
      this.$router.push(`/quotePriceManagement/quotePriceDetailsView?id=${this.targetPurchaseId}&&quoteSource=1`)
    },
    handleCancel() {
      this.visible = false
      this.targetPurchaseId = null
    },
    /* 确定参加需求 */
    handleJoinPlan(item) {
      this.targetPurchaseId = item.purchaseId
      this.$router.push(`/quotePriceManagement/quotePriceDetailsView?id=${item.purchaseId}&&quoteSource=1`)
      // this.visible = true
    },
  },
}
</script>

<style lang="less" scoped>
.statusBox {
  margin-bottom: 15px;
}

.ant-card {
  border-radius: 8px;
  background: #fffff8;

  ::v-deep .ant-tabs-card-bar {
    margin-bottom: 0;
  }

  ::v-deep .ant-tabs-tabpane-active {
    border: 1px solid #e4e4e4;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 15px 15px 0;
    background: #fff;
  }

  .tableCardTitle {
    text-align: center;
    width: 100%;
    padding: 8px 15px;
    background: #f7f7f7;
    margin-bottom: 15px;
    .flexLayout();
    font-size: 13px;
    color: #66766d;

    div.options {
      width: 186px;
    }

    div.left-title {
      width: 300px;
      text-align: left;
    }

    div.orderNum {
      width: 140px;
    }

    .status {
      width: 120px;
    }
  }
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    margin-left: 8px;
    .ant-btn {
      width: 97px;
      height: 38px;

      &+.ant-btn {
        margin-left: 8px;
      }
    }
  }
}

::v-deep.ant-tabs {
  color: rgba(0, 0, 0, 0.85);
}

::v-deep.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:not(.ant-tabs-tab-active) {
  background-color: #f7f8fa;
}

::v-deep .ant-tabs-tab-active {
  font-weight: 500;
}

.ant-divider {
  background-color: #FF6026;
}

::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .ant-table-column-title {
  font-size: 16px;
}

::v-deep .ant-table-thead {
  tr {
    background: #efefef;

    th {
      border: none;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    &:not(:last-of-type)>td {
      border: none;
    }

    &>td {
      border-bottom: 1px solid #EFEFEF;
    }
  }
}

::v-deep .ant-table-thead>tr>th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;

}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-tabs-nav .ant-tabs-tab {
  padding: 15px 0 25px;
  font-size: 16px;

  &.ant-tabs-tab-active {
    font-weight: 500;

  }
}

::v-deep .ant-tabs-card-bar .ant-tabs-tab {
  padding: 0 16px !important;
  font-weight: 400 !important;
  font-size: 14px;
}

@import '~@/styles/list.less';

.card-info-box.last-box {
  padding-top: 10px;
}

::v-deep .ant-calendar-picker-input.ant-input {
  padding: 0 11px;
  height: 24px;
}

::v-deep .ant-calendar-picker-input .ant-calendar-range-picker-input {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
}

.table-card-cover-item .left-more-info .counter {
  grid-gap: 0 0;
}
.table-card-cover-item {
  padding-top: 40px;
}

.area-block {
  display: flex;
  justify-content: flex-start;

  .left-choose {
    // flex: 1 0 0;
    margin-right: 8px;
  }

  .ant-btn-link {
    font-weight: 400;
    color: #FF6026;
  }
}

.info-card {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.left-more-info {
  min-width: 0;
}

.counter {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span.category {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  .placeholder {
    color: #999;
  }
}
.cover_btn {
  display: flex;
  flex-direction: column;
}
</style>
