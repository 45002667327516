<!--
 * @Description:采购商确认合作数量
-->
<template>
  <DrawerView :visible="visible" :width="800" :title="'确定合作数量并合作'" show-slot-title @cancel="handleCancel">
    <div class="priceDetails">
      <a-form-model
        :class="'form-cover1 form-cover'"
        ref="basicForm"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :colon="false"
      >
        <div class="card-info-box" style="padding: 0px 24px 0px 0px">
          <a-table :columns="columns" :pagination="false" :data-source="quoteList" bordered>
            <template slot="skuName" slot-scope="text, record, index">
              <span style="padding-left: 8px">{{ text }}</span>
            </template>
            <template slot="quotePrice" slot-scope="text, record, index">
              <span style="padding-left: 8px">{{ text  }}</span>
            </template>

            <template slot="quoteSkuQuantity" slot-scope="text, record, index">
             <a-input-number :defaultValue="record.quoteSkuQuantity" min="0" style="width: 100%" @change="handleChangeInput($event, record)"></a-input-number>
            </template>
            <template slot="totalPrice" slot-scope="text, record, index">
            <div class="td" style="padding-left: 8px">  {{ multiply((record.quoteSkuQuantity || 0), (record.quotePrice || 0)) }}</div>
            </template>
            <template slot="footer" slot-scope="currentPageData">
              <div class="footer-info-bar">
                <span class="title">合计（元）</span>
                <div class="total">
                  {{ allTotal }}
                </div>
              </div>
            </template>
          </a-table>

        </div>
      </a-form-model>
    </div>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="onConfirm" :loading="loading" type="primary">确定</a-button>
    </template>
    <a-modal
      title="温馨提示"
      :width="600"
      :visible="showInfo"
      okText="确认"
      centered
      @ok="chooseHim"
      @cancel="showInfo = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>点击“确认”即表示确认合作关系，合作关系成功建立后，请在“订单管理”操作合作流程。</span>
      </div>
    </a-modal>
  </DrawerView>
</template>
<script>
import { defineComponent } from 'vue'
import DrawerView from '@/components/plugins/drawerView.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import { getAction, postAction } from '@/api/manage'
import uploadFile from '@/components/plugins/uploadFile.vue'
import UploadDrag from '@/components/plugins/uploadDrag.vue'
import { mapGetters } from 'vuex'
import ZPagination from '@/components/plugins/ZPagination.vue'
import ViewContract from '@/views/ContractManagement/modules/viewContract.vue'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import { getCurBusiness } from '@/util/utils'

export default defineComponent({
  components: { checkboxSearch, ViewContract, UploadDrag, uploadFile, JDictSelect, DrawerView, ZPagination }
  ,
  data() {
    return {
      showInfo: false,
      visible: false,
      enquiryLadders: [],
      form: { modeType: 1 },
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      formBaseInfo: {},
      formFile: {},
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      },
      columns: [
        {
          title: '报价散件',
          dataIndex: 'skuName',
          width: 150,
          scopedSlots: { customRender: 'skuName' }

        },
        {
          title: '合作数量',
          dataIndex: 'quoteSkuQuantity',
          width: 180,
          scopedSlots: { customRender: 'quoteSkuQuantity' }
        },
        {
          title: '报价单价（元）',
          dataIndex: 'quotePrice',
          width: 200,
          scopedSlots: { customRender: 'quotePrice' }
        },
        {
          title: '合计（元）',
          dataIndex: 'totalPrice',
          width: 177,
          scopedSlots: { customRender: 'totalPrice' }
        }
      ], dataSourceCurrent: [
        { num: 233, price: 10, total: 20 },
        { num: 22, price: 10, total: 20 },
        {
          num: 443, price: 10, total: 20
        }],
      loading: false,
      quoteList: [],
      purchaseId: null,
      quoteId: null,
      businessData: {},
      allTotal:0,
    }
  },
  created() {
    this.businessData = getCurBusiness()
  },
  methods: {
    ...mapGetters(['dataSource']),
    handleOpen(quoteList, purchaseId, quoteId) {
      this.quoteList = [...quoteList]
      this.purchaseId = purchaseId
      this.quoteId = quoteId
      let allTotal = 0
      this.quoteList.map(item => {
        item.quoteSkuQuantity = item.skuQuantity
        if (item.purchaseEnquiryLadder != null && item.purchaseEnquiryLadder != undefined) {
          item.purchaseEnquiryLadder.map(item1 => {
            if (item1.minCount <= item.quoteSkuQuantity && item1.maxCount >= item.quoteSkuQuantity) {
              item.quotePrice = item1.unitPrice
            } else if (item.quoteSkuQuantity >= item1.minCount && item1.maxCount == null) {
              item.quotePrice = item1.unitPrice
            }
          })

        }
        if ((item.quoteSkuQuantity != null && item.quoteSkuQuantity > 0) && (item.quotePrice != null && item.quotePrice > 0)) {
          allTotal += item.quoteSkuQuantity * item.quotePrice
        }

      })
      this.allTotal = allTotal
      this.visible = true
    },
    multiply(a, b) {
      const precision = this.getPrecision(a) + this.getPrecision(b)
      const multiplier = Math.pow(10, precision)
      return (Math.round(a * multiplier) * Math.round(b * multiplier)) / (multiplier * multiplier)
    }, getPrecision(num) {
      const str = String(num)
      const decimalIndex = str.indexOf('.')
      return decimalIndex === -1 ? 0 : str.length - decimalIndex - 1
    },
    handleCancel() {
      this.visible = false
    }, saleInfoDelete() {
      this.visibleDel = true
    },
    submit() {
      this.$emit('ok')
      this.visible = false
    }, deleteLadder(index) {
      this.enquiryLadders.splice(index, 1)
    },
    toAddLadder() {
      this.enquiryLadders.push({})
    },
    onConfirm() {
      let hasQuoteSkuQuantity = this.quoteList.some((i) => (i.quoteSkuQuantity == 0 || i.quoteSkuQuantity == null || i.quoteSkuQuantity == ''))
      // console.log(hasQuoteSkuQuantity)
      if (hasQuoteSkuQuantity) {
        return this.$message.warning('请输入合作数量！')
      }
      this.showInfo = true
    },
    handleChangeInput(e, item) {
      // 获取输入的原始值
      console.log(e)
      let inputValue = e

      // 替换非数字字符（只保留数字和一个小数点）
      // inputValue = inputValue.replace(/[^\d.]/g, '').replace(/(\..*?)\..*/g, '$1')

      // 如果存在小数点，确保小数点后最多四位
      // if (inputValue.includes('.')) {
      //   inputValue = inputValue.replace(/(\.\d{4})./g, '$1')
      // }

      // 如果需要禁止负数（可选）
      if (parseFloat(inputValue) < 0) {
        inputValue = inputValue.replace('-', '') // 移除负号
        // 或者你可以选择设置inputValue为0或某个默认值
      }
      item.quoteSkuQuantity = inputValue
      if (item.quoteMode == 2) {
        let price = 0
        let ladder = []
        item.purchaseEnquiryLadder.map(item => {
          ladder.push({ intervalNumber: item.intervalNumber, unitPrice: item.unitPrice })
        })
        ladder.sort((a, b) => a.intervalNumber - b.intervalNumber)

        ladder = ladder.map((item, index, array) => {
          if (index < array.length - 1) {
            return { ...item, maxCount: array[index + 1].intervalNumber - 1, minCount: item.intervalNumber }
          } else {
            return { ...item, maxCount: item.intervalNumber }
          }
        })

        console.log(ladder)
        ladder.map(item => {
          if (parseInt(inputValue) >= parseInt(item.minCount) && parseInt(inputValue) <= parseInt(item.maxCount)) {
            price = item.unitPrice
          } else if (parseInt(inputValue) >= parseInt(item.maxCount)) {
            price = item.unitPrice
          }
        })

        // item.purchaseEnquiryLadder.map(item1 => {
        //   if (item1.intervalNumber >= item.quoteSkuQuantity) {
        //     price = item1.unitPrice
        //   }
        // })
        console.log('price:' + price)
        item.quotePrice = price
      }
      console.log('item.quoteSkuQuantity' + item.quoteSkuQuantity)
      console.log(item.quotePrice)
      //把总数进行处理下
      let allTotal = 0
      this.quoteList.map(item => {
        allTotal += item.quoteSkuQuantity * item.quotePrice
      })
      this.allTotal = allTotal

      this.$forceUpdate()
    },
    chooseHim() {
      this.showInfo = false
      this.visible = false


      this.quoteList.map(item => {
        if (item.quoteSkuQuantity != null && item.quoteSkuQuantity != undefined) {
          item.skuQuantity = item.quoteSkuQuantity

        }
        if (item.purchaseEnquiryOrigin != null && item.purchaseEnquiryOrigin != undefined) {
          item.purchaseEnquiryOrigin.map(item1 => {
            item1.number = item.skuQuantity
          })
        }
        item.skuPrice = item.quotePrice
      })
      const payload = {}
      payload.purchaseId = this.purchaseId
      payload.businessId = this.businessData.businessId
      payload.quoteId = this.quoteId
      payload.quoteItems = this.quoteList

      // 接口
      postAction('/quote/confirm', payload).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          this.$router.replace('/requirement/requirementList')
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleSumNum() {

    }
  }
})

</script>
<style lang="less" scoped>
.invoiceDetails {
  box-sizing: border-box;
  background-color: #ffffff;
}

.RequirementConfirmation {
  .header {
    &-title {
      color: #090b12;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 15px;

      .copy {
        font-size: 13px;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }

  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    position: relative;

    &:before {
      background: #FF6026;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 14px;
      color: #8d8e99;
    }
  }

  &-content {
    &-item {
      width: 100%;
      background: #fff;
      position: relative;

      .edit-btn {
        position: absolute;
        top: 4px;
        right: -15px;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep .ant-form-item-label {
    line-height: 22px;

    label {
      color: #626161 !important;
    }
  }

  ::v-deep .ant-form-item-control {
    line-height: 22px;
    color: #131212;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subTitle {
    width: calc(100% - 40px);
    font-size: 15px;
    color: #FF6026;
    padding-bottom: 8px;
    border-bottom: 1px solid #efefef;
    margin: 0 auto 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag-title {
      padding: 2px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background: #fff2ea;
      border-radius: 2px;
    }

    img {
      width: 15px;
      margin-right: 4px;
    }

    span {
      line-height: 22px;
      font-size: 14px;
    }
  }

  .basicInfo-form {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 18px 0 4px;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &-item {
      padding: 0 20px;
    }
  }

  ::v-deep .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-btn-link[disabled] img {
    filter: grayscale(100%);
  }

  .price-title {
    width: 107px;
    margin-top: 8px;
  }

  .process-content {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;

    ::v-deep .ant-timeline-item-tail {
      border: 0.5px dashed #dcdcdc;
    }

    ::v-deep .ant-timeline-item-head {
      width: 5px;
      height: 5px;
      background: #d7d7db;
      border: none;
      left: 2px;
      top: 3px;
    }

    .content {
      padding-top: 5px;
      color: #000000;
      font-size: 14px;
      line-height: 20px;
    }

    .time {
      font-size: 14px;
      line-height: 20px;
      color: #8d8e99;
    }
  }
}

.ant-descriptions {
  // background-color: #f7f8fa;
  // padding: 16px 25px 0;
  // border-radius: 8px;

  &.tag_2 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

::v-deep .ant-descriptions-item-label {
  color: #595959;
}

::v-deep .ant-descriptions-item-content {
  color: #131212;
}

::v-deep .ant-radio-disabled + span {
  color: #262626;
}

.card-view {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px 25px;

  &-item {
    width: calc((100% - 50px) / 3);
    height: 144px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px dashed #e8e9eb;
    padding: 15px;
    display: flex;
    position: relative;

    .cover {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        & + .option-area {
          opacity: 1;
          z-index: 10;
        }
      }
    }

    .option-area {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.65);
      position: absolute;
      left: 15px;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      z-index: -1;

      &:hover {
        opacity: 1;
        z-index: 10;
      }

      img {
        height: 16px;
        cursor: pointer;
      }

      .split-line {
        width: 1px;
        height: 14px;
        background-color: #fff;
        margin: 0 15px;
      }
    }
  }

  .info-card {
    &.inside {
      flex: 1 0 0;
      min-width: 0;
    }

    .sub_title {
      font-size: 14px;
      font-weight: 400;
      color: #131212;
      line-height: 22px;
      margin-bottom: 8px;
      overflow: hidden;
      .text-ellipsis-multi(2);
    }

    div.labels {
      font-size: 12px;
      color: #8d8e99;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.desc-title {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 12px;
  margin-bottom: 15px;

  span.title {
    font-size: 14px;
    color: #131212;
  }
}

div.file-card {
  width: max-content;
  height: 64px;
  background: #fff;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  margin-bottom: 8px;

  &-title {
    margin-bottom: 15px;

    img.icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .fileName {
      color: #1d2129;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-size {
    font-weight: 400;
    color: #8d8e99;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    padding-left: 22px;
  }
}

.coverBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.last-label {
  width: 100%;
  height: 102px;
  background-image: url('~@/assets/longerBG.png');
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  .ant-form-item {
    margin-bottom: 0;
  }

  ::v-deep .ant-input {
    border-color: #ff9a57;
  }

  ::v-deep .ant-input-suffix {
    color: #131212;
  }

  .moneyIcon {
    position: absolute;
    bottom: 0;
    right: 19px;
  }
}

.numberInput {
  width: 320px;
  border-color: #ff7d2f;
  height: 38px;
  line-height: 38px;

  ::v-deep .ant-input-number-input {
    font-size: 18px;
    color: #131212;
    font-weight: 500;
  }
}


@import '~@/styles/form.less';
@import '~@/styles/orderDetail.less';
.action {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 14px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  img {
    width: 20px;
    margin-right: 4px;
  }
}

::v-deep .ant-form-item-label {
  width: 106px !important;
  display: flex;
  text-align: right !important;
  justify-content: flex-end;
}

.sale-table {
  .quote-table {
    padding: 0 15px;
    overflow-x: auto;

    .ant-table-wrapper {
      width: 1056px;
    }

    ::v-deep .ant-table-thead > tr > th {
      background: #F4F5F7;
    }

    ::v-deep .ant-table-footer {
      height: 48px;
      padding: 0;

      .footer-info-bar {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 16px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
        }


      }
    }
  }
}

::v-deep .ant-table-footer {
  //height: 56px !important;
  padding: 0px !important;
}

.orderno-tag {
  //padding: 9px 4px 9px 12px;
  //min-width: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 10px;
  background: #E8E9EB;
  border-radius: 3px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  margin-right: 4px;
}

.saleName {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #264AFF;
  /* 状态标签 */
  display: flex;
  flex-direction: row;
  //justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  background: #E2E7FB;
  border-radius: 3px;

}

.saleTitleGroup {
  display: flex;

}

.saleTitle, .salePart {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.85);
  background: #F4F5F7;
  border: 1px solid #E8E9EB;
  width: 100%;
  padding: 10px 16px;

  font-family: 'PingFang SC';
  font-style: normal;

  font-size: 14px;
  line-height: 150%;
  /* 文字色/标题+主要 */
  color: rgba(0, 0, 0, 0.85);
  border-right: 0px;
}

.saleTitle {
  font-weight: bold;
}

.salePart {
  background-color: #ffffff;
  border-top: 0px;
  border-right: 0px;
}

.sale-table {
  border: 1px solid #E8E9EB;
  margin-top: 24px;
}

.openingInvocie {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding-right: 16px;
}

.info-card {
  font-size: 16px;
  color: #131212;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.uploadCover {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .sale-table[data-v-cd4a0cfc] {
  border-radius: 4px;
}

::v-deep .ant-table table {
  border-radius: 4px;
}

.num-style {

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #1681FF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: rgba(22, 129, 255, 0.1);
  border-radius: 8px;
  width: 61px;
  height: 28px;
}

.footer-info-bar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 16px;
  box-sizing: border-box;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  .total {
    height: 100%;
    border-left: 1px solid #E8E9EB;
    width: 176.5px;
    line-height: 46px;
    padding-left: 16px;
    color: #FF6026;
    font-size: 16px;
  }


}
</style>

