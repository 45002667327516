<template>
  <DrawerView :visible="visible" :title="modalTitle" :width="700" @confirm="handleOk"
              @cancel="handleCancel" :showSlotTitle="true">
    <div class="">
      <div class="card-info-box" style="padding: 0px 24px 24px 24px">
        <a-form-model
          :class="form.headType==2?'form-cover2 form-cover':'form-cover1 form-cover'"
          ref="basicForm"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :colon="false"
        >
          <a-row :gutter="26" class="basicInfo-form-item">
            <a-col :span="26" style="padding: 0px 0px 20px 0px">
              <a-form-model-item
                label="产品名称"
                prop="designName" :rules="{ required: true, message: '请输入产品名称' }"
              >
                <a-input v-model="form.designName" placeholder="请输入产品名称" style="width: 480px; height: 38px;margin-left: 16px"
                ></a-input>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="padding: 0px 0px 20px 0px">
              <a-form-model-item
                label="包装材料"
                prop="packingMaterial"
                :rules="{ required: true, message: '请选择包装材料' }"
              >
                <j-dictSelect v-model="form.packingMaterial" dictCode="1507" placeholder="请选择"
                              style="width: 480px; height: 38px;margin-left: 16px" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="padding: 0px 0px 20px 0px">
              <a-form-model-item
                label="包装结构"
                prop="packingStruct"
                :rules="{ required: true, message: '请选择包装结构' }"
              >
                <j-dictSelect v-model="form.packingStruct" dictCode="1551" placeholder="请选择"
                              style="width: 480px; height: 38px;margin-left: 16px" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="padding: 0px 0px 20px 0px">
              <a-form-model-item
                label="设计风格"
                prop="designStyle"
                :rules="{ required: true, message: '请选择设计风格' }"
              >
                <j-dictSelect v-model="form.designStyle" dictCode="1547" placeholder="请选择"
                              style="width: 480px; height: 38px;margin-left: 16px" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="padding: 0px 0px 20px 0px">
              <a-form-model-item
                label="设计类别"
                prop="designCategory"
                :rules="{ required: true, message: '请选择设计类别' }"
              >
                <j-dictSelect v-model="form.designCategory" dictCode="1544" placeholder="请选择"
                              style="width: 480px; height: 38px;margin-left: 16px" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="padding: 0px 0px 20px 0px">
              <a-form-model-item
                label="包装用途"
                prop="packingPurpose"
                :rules="{ required: true, message: '请选择包装用途' }"
              >
                <j-dictSelect v-model="form.packingPurpose" dictCode="1508" placeholder="请选择"
                              style="width: 480px; height: 38px;margin-left: 16px" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="padding: 0px 0px 20px 0px">
              <a-form-model-item
                label="案例金额/元"
                prop="caseAmount" :rules="{ required: true, message: '请输入案例金额', trigger: 'blur'  }"
              >
                <a-input placeholder="请输入案例金额" min="0" v-model="form.caseAmount" style="width: 480px; height: 38px;margin-left: 16px"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="padding: 0px 0px 20px 0px">
              <a-form-model-item
                label="产品图片"
                prop="casePictures"
                :rules="{ required: true, message: '请上传图片' }"
              >
                <uploadFile
                  :number="6"
                  v-model="form.casePictures"
                  :showUploadList="false"
                  :uploadFileType="'image'"
                  :defaultParams="{
                    dir: 'picture',
                  }"
                  :needMemo="false"
                  style="margin-left: 16px"

                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="padding: 0px 0px 20px 0px">
              <a-form-model-item
                label="产品描述"
                prop="caseDesc"
              >
                <a-textarea
                  placeholder="请输入内容"
                  allow-clear
                  style="margin-left: 6px"
                  v-model="form.caseDesc"
                />
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-form-model>

      </div>

    </div>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button v-if="form.status==0" @click="handleUpdateStatus" :loading="loading" type="primary">保存并上架
      </a-button>
      <a-button v-else @click="handleOk" :loading="loading" type="primary">保存</a-button>
    </template>
  </DrawerView>
</template>

<script>
import DrawerView from '@/components/plugins/drawerView.vue'
import { mapGetters } from 'vuex'
import { postAction, getAction } from '@/api/manage'
import Captcha from '@/components/tools/captcha.vue'
import WinTitle from '@/components/winTitle/index.vue'
import region from '@/util/region.json'
import uploadFile from '@/views/businessManagement/companyIntro/Supplier/modules/uploadProductFile.vue'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import { getCurBusiness, initUploadFile } from '@/util/utils'

export default {
  components: {
    JDictSelect,
    checkboxSearch,
    uploadFile,
    DrawerView,
    Captcha,
    WinTitle
  },
  data() {

    return {
      form: { productPictureList: [] },
      modalTitle: '添加产品',
      region,
      sendPhone: null,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      visible: false,
      loading: false,
      info: {},
      sendCodeSecA: 0,
      sendCodeSecB: 0,
      type: 1,
      text: '',
      productId: null

    }
  },
  created() {
    this.curBusiness = getCurBusiness()

  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleEdit() {
      const { userId, phone } = this.setUserInfo()
      this.info = {
        userId,
        oldPhone: phone
      }
      getAction('/user/pc/user/queryUserPhone', { userId }).then(res => {
        const { success, data } = res
        if (success && data) {

          this.$set(this.info, 'oldPhone', data)

        }
      })
      this.visible = true
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.form = {}
    },
    handleOpen(item) {
      this.form = { ...item }
      console.log(item)
      this.form.businessId = this.curBusiness.businessId
      console.log(this.form.businessId)
      this.modalTitle = '添加产品'
      if (item?.id) {
        this.modalTitle = '编辑产品'
        console.log(this.form.casePictures)
        this.form.casePictures = initUploadFile(this.form.casePictures.toString())
      } else {
        // this.productPictureList = []

      }
      this.visible = true
      this.loading = false
    },
    handleOk() {
      const that = this
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          const {
            id,
            caseAmount,
            caseDesc,
            designCategory,
            designName,
            designStyle,
            packingMaterial,
            packingPurpose,
            packingStruct
          } = this.form
          let params = {
            id,
            caseAmount,
            caseDesc,
            designCategory,
            designName,
            designStyle,
            packingMaterial,
            packingPurpose,
            packingStruct
          }
          params.businessId = this.curBusiness.businessId
          params.casePictures = this.form.casePictures.map(item => `${item.url}`)
          params.status = 0
          this.loading = true
          const apiUrl = this.form.id ? '/v2/design/case/update' : '/v2/design/case/save'
          postAction(apiUrl, { ...params }).then(res => {
            const { message, success } = res
            that.loading = false
            if (success) {
              that.$message.success('保存成功')
              this.$emit('ok')
              that.handleCancel()
            } else {
              that.$message.warning(message)
            }
          })
        }
      })
    }, handleUpdateStatus() {
      const that = this
      this.$refs.basicForm.validate(valid => {
        if (valid) {
          const {
            id,
            caseAmount,
            caseDesc,
            designCategory,
            designName,
            designStyle,
            packingMaterial,
            packingPurpose,
            packingStruct
          } = this.form
          let params = {
            id,
            caseAmount,
            caseDesc,
            designCategory,
            designName,
            designStyle,
            packingMaterial,
            packingPurpose,
            packingStruct
          }
          params.businessId = this.curBusiness.businessId
          if (this.form.casePictures) {
            params.casePictures = this.form.casePictures.map(item => `${item.url}`)
          }
          params.status = 1
          console.log(params)
          this.loading = true
          if (this.form.id) {
            postAction('/v2/design/case/update', params).then(res => {
              const { message, success } = res
              that.loading = false
              if (success) {
                that.$message.success('保存成功')
                this.$emit('ok')
                that.handleCancel()
              } else {
                that.$message.warning(message)
              }
            })
          }
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>

::v-deep .ant-form-item {
  margin-bottom: 14px;
}

::v-deep .ant-form-item-required::before {
  //display: none;
}

::v-deep .ant-btn-link {
  padding: 0;
}

::v-deep .ant-input {
  margin-left: 10px;
}

::v-deep .ant-input-suffix {
  right: 10px;
}

::v-deep .ant-input {
  height: 38px;
}

::v-deep textarea {
  min-height: 38px;
}

::v-deep .ant-cascader-picker-label {
  padding-left: 20px;
}

::v-deep .ant-select-selection--single {
  height: 38px;
}

::v-deep .ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  width: 480px;
  height: 160px !important;
}

::v-deep [data-v-51827fa8] .ant-input {
  margin-left: 0px;
}

::v-deep .contentView[data-v-438c6895] {
  overflow: unset;
}

::v-deep .ant-drawer-body {
  padding: 24px !important;
}
</style>

