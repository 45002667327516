<!--
 * @Description: 产品的轮播图
 * @Author: zhang zhen
 * @Date: 2024-09-23 13:35:09
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-26 17:28:17
 * @FilePath: /page-sass/src/components/tools/productSwiper.vue
-->

<template>
  <div class="product-swiper">
    <!-- 没图的时候 -->

    <template v-if="!productList || !productList.length">
      <div class="emptyInfo">
        <img src="/newEmpty.png" alt="" class="icon" />
        <span class="desc">暂无数据</span>
      </div>
    </template>
    <template v-if="productList && productList.length == 1">
      <template v-if="productList && productList.length>0">
        <div class="pic-box" v-for="(item, index) in productList" :key="index">
          <img :src="item.productPicture[0]" alt="产品图片" class="pic" />
          <div class="inlineDesc">{{ item.productDesc }}</div>
        </div>
      </template>
    </template>
    <template v-if="productList && productList.length > 1 && msgCategory!=2">
      <div :class="'swiper-container swiper-container-'+index">
        <div class="swiper-wrapper">
          <div class="swiper-slide pic-box" v-for="(item, index) in productList" :key="index">
            <div v-if="item.productPicture">
              <div v-for=" (i,index) in item.productPicture">
                <img :src="i" alt="产品图片" class="pic" v-if="index==0" />
              </div>
              <div class="inlineDesc">{{ item.productName }}</div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next">
          <img src="/product_right_arrow.png" alt="" class="new_arrow" />
        </div>
        <div class="swiper-button-prev">
          <img src="/product_left_arrow.png" alt="" class="new_arrow" />
        </div>
      </div>
    </template>
    <template v-if="productList && productList.length > 1 && msgCategory==2">
      <div :class="'swiper-container swiper-container-'+index" style="width: 200px;">
        <div class="swiper-wrapper">
          <div class="swiper-slide pic-box" v-for="(item, index) in productList" :key="index">
            <div v-if="item.casePictures">
              <div v-for=" (i,index) in item.casePictures">
                <img :src="i" alt="产品图片" class="pic" v-if="index==0" />
              </div>
              <div class="inlineDesc">{{ item.caseDesc }}</div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next">
          <img src="/product_right_arrow.png" alt="" class="new_arrow" />
        </div>
        <div class="swiper-button-prev">
          <img src="/product_left_arrow.png" alt="" class="new_arrow" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>


export default {
  props: {
    productList: {
      type: Array,
      default: () => []
    },
    msgCategory: {
      type: Number,
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.handleInitData()
  },
  methods: {
    handleInitData() {
      var swiper = new Swiper('.swiper-container-'+this.index, {
        // slidesPerView: 1.6,
        // spaceBetween: 30,
        //  slidesPerGroup: 3,
        loop: false,
        loopFillGroupWithBlank: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.product-swiper,.product-swiper-big {
  width: 200px;
  height: 218px;
  position: relative;

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      display: block;
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-button-next,
    .swiper-button-prev {
      &::after {
        display: none;
      }
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev {
      left: 0;
    }

    .pic-box {
      width: 300px;
      height: 225px;
      position: relative;

      .inlineDesc {
        width: 300px;
      }
    }
  }

  .emptyInfo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    .desc {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      text-align: center;
      color: #9c9ea9;
    }

    img.icon {
      width: 118px;
      margin-bottom: 10px;
    }
  }

  .pic-box {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 156px;
      border-radius: 8px;
      object-fit: cover;
      //aspect-ratio: 16/9;
    }

    &:hover {
      img {
        transition: all 500ms ease-in;
        transform: scale(1.5);
      }

      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.32);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
      }

      .inlineDesc {
        background: transparent;
      }
    }
  }

  .inlineDesc {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.396) 0%, rgba(0, 0, 0, 0) 100%);
    color: #ffffffe5;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    position: absolute;
    padding: 0 6px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 38px;
    line-height: 38px;
    border-radius: 8px;
    z-index: 2;
  }

  .new_arrow {
    width: 38px;
    height: 36px;
  }
}
.product-swiper-big{
  width: 600px;
}
</style>
