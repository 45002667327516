<!--
 * @Description: 订单管理
 * @Author: zhang zhen
 * @Date: 2023-02-15 16:18:10
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-29 12:32:03
 * @FilePath: /page-sass/src/views/requirement/orderManagement/index.vue
-->
<template>
  <div class="infoCard">
    <SupplierProductCaseList v-if="curBusiness.roleType==0" />
    <FacilitatorProductCaseList v-if="curBusiness.roleType==1" />
    <DesignerProductCaseList v-if="curBusiness.roleType==4"/>
  </div>
</template>
<script>
import InterestedCard from '@/components/tools/interestedCard.vue'
import ZPagination from '@/components/plugins/ZPagination.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters, mapActions } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'

import { postAction, downFiles, getAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import { getCurBusiness, getDateRange } from '@/util/utils'

import dayjs from 'dayjs'
import { size } from 'lodash/collection'
import SupplierProductCaseList from '@/views/businessManagement/productCase/supplier/productCaseList.vue'
import FacilitatorProductCaseList from  '@/views/businessManagement/productCase/facilitator/productCaseList.vue'
import DesignerProductCaseList from '@/views/businessManagement/productCase/designer/productCaseList.vue'
export default {
  name: 'orderManagement',
  mixins: [ListMixin],
  components: {
    checkboxSearch,
    InterestedCard,
    ZPagination,
    EmptyArea,
    radioGroup,
    SupplierProductCaseList,
    FacilitatorProductCaseList,
    DesignerProductCaseList
  },
  provide() {
    return {
      handleReloadData: this.handleLoadData
    }
  },
  data() {
    return {
      curBusiness: {},

      visible: false,
    }
  },
  created() {
    this.curBusiness = getCurBusiness()
    console.log('当前用户')
    console.log(this.curBusiness)
  },
  methods: {}

}
</script>

<style lang="less" scoped>

</style>
