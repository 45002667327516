<template>
  <DrawerView :visible="visible" :width="800" :title="'成本价报价'" show-slot-title @cancel="handleCancel">
    <div class="priceDetails">
      <a-form-model
        :class="'form-cover1 form-cover'"
        ref="basicForm"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :colon="false"
      >
        <div class="card-info-box" style="padding: 0px 24px 0px 0px">
          <a-row>
            <a-col class="cost-mun">
              数量：{{ purchaseItem.dataSource[0].num }}
            </a-col>
          </a-row>
        </div>
        <div>
          <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">

            <h2 class="RequirementConfirmation-title">
              <span class="title">成本价构成</span>
              <a class="action">
              </a>
            </h2>

            <div class="card-info-box " style="padding: 0px 24px 0px 0px">
              <a-row :gutter="26" class="basicInfo-form-item">
                <div v-for="item in costCombList">
                  <a-col :span="26" v-if="item.name=='原材料费'">
                    <a-form-model-item
                      label="原材料费单价/元"
                      prop="yclfPrice"
                      style="display: flex;flex-direction: row"
                      :rules="{ required: true, message: '请输入' }"
                    >
                      <a-input placeholder="请输入" v-model="form.yclfPrice" style="width: 320px;"
                               @change="toAllTotal"></a-input>
                    </a-form-model-item>
                  </a-col>

                  <a-col :span="26" v-if="item.name=='工序费'">
                    <a-form-model-item
                      label="工序费单价/元"
                      prop="gxfPrice"
                      style="display: flex;flex-direction: row"
                    >
                      <a-input placeholder="请输入" style="width: 320px; " v-model="form.gxfPrice"
                               @change="toAllTotal"></a-input>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="26" v-if="item.name=='管理费'">
                    <a-form-model-item
                      label="管理费单价/元"
                      prop="glfPrice"
                      style="display: flex;flex-direction: row"
                    >
                      <a-input placeholder="请输入" style="width: 320px;" v-model="form.glfPrice"
                               @change="toAllTotal"></a-input>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="26" v-if="item.name=='运费'">
                    <a-form-model-item
                      label="运费单价/元"
                      prop="yfPrice"
                      style="display: flex;flex-direction: row"
                    >
                      <a-input placeholder="请输入" style="width: 320px;" v-model="form.yfPrice"
                               @change="toAllTotal"></a-input>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="26" v-if="item.name=='税费'">
                    <a-form-model-item
                      label="税费单价/元"
                      prop="sfPrice"
                      style="display: flex;flex-direction: row"
                    >
                      <a-input placeholder="请输入" style="width: 320px;" v-model="form.sfPrice"
                               @change="toAllTotal"></a-input>
                    </a-form-model-item>
                  </a-col>
                </div>
                <a-col :span="26">
                  <a-form-model-item
                    class="ant-form-item-price"
                    label="总报价单价/元"
                    prop="total"
                    style="display: flex"
                  >
                    <span style="color: #FF6026">{{ form.total }}</span>

                  </a-form-model-item>
                </a-col>
              </a-row>

            </div>
          </div>

        </div>

      </a-form-model>
    </div>
    <template v-slot:btnArea>
      <a-button @click="handleCancel">取消</a-button>
      <a-button @click="submit()" :loading="loading" type="primary">确定</a-button>
    </template>
  </DrawerView>
</template>
<script>
import { defineComponent } from 'vue'
import DrawerView from '@/components/plugins/drawerView.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import { getAction, postAction } from '@/api/manage'
import uploadFile from '@/components/plugins/uploadFile.vue'
import UploadDrag from '@/components/plugins/uploadDrag.vue'
import { mapGetters } from 'vuex'
import ZPagination from '@/components/plugins/ZPagination.vue'
import ViewContract from '@/views/ContractManagement/modules/viewContract.vue'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import { isEmpty } from 'lodash'
import { getCurBusinessId } from '@/util/utils'

export default defineComponent({
  components: { checkboxSearch, ViewContract, UploadDrag, uploadFile, JDictSelect, DrawerView, ZPagination }
  ,
  data() {
    return {
      loading: false,
      enquiryLadders: [],
      form: { modeType: 1, enquiryOrigin: {} },
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      formBaseInfo: {},
      formFile: {},
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      },

      visibleDel: false,
      visible: false,
      costCombList: [],
      totalPrice: 0,
      purchaseItem: {}
    }
  },
  created() {
  },
  methods: {
    ...mapGetters(['dataSource']),
    handleOpen(item, type) {
      this.purchaseItem = {}
      this.costCombList = []
      console.log('成本报价')
      console.log(item)
      this.purchaseItem = { ...item }


      if (this.purchaseItem.dataSource[0].preEnquiryOrigin != null && this.purchaseItem.dataSource[0].preEnquiryOrigin != undefined) {
        this.purchaseItem.dataSource[0].preEnquiryOrigin.compose.split(',').map((name, index) => {
          let cost = null
          if (name == '原材料费') {
            cost = { id: 0, name: name }
          } else if (name == '工序费') {
            cost = { id: 1, name: name }
          } else if (name == '管理费') {
            cost = { id: 2, name: name }
          } else if (name == '运费') {
            cost = { id: 3, name: name }
          } else if (name == '税费') {
            cost = { id: 4, name: name }
          }
          this.costCombList.push(cost)
        })
        this.costCombList.sort((a, b) => a.id - b.id)
        this.quoteInquiryMode = type
      }

      this.visible = true
    },
    handleCancel() {
      this.visible = false
    }, saleInfoDelete() {
      this.visibleDel = true
    },
    handleChangePage({ currentPage, pageSize }) {
      this.pageNation.pageNum = currentPage
      this.pageNation.pageSize = pageSize
      this.handleLoadData()
    },

    submit() {
      if (this.form.yclfPrice == null || this.form.yclfPrice == undefined) {
        return this.$message.warning('请输入原材料费单价')
      }

    console.log(this.purchaseItem.dataSource[0].enquiryOrigin.compose)
      this.purchaseItem.dataSource[0].quoteMode = this.quoteInquiryMode
      this.purchaseItem.dataSource[0].isEdit = false
      console.log(this.purchaseItem)
      this.$emit('ok', this.purchaseItem)

      this.visible = false
    }, deleteLadder(index) {
      this.enquiryLadders.splice(index, 1)
    },
    toAddLadder() {
      this.enquiryLadders.push({})
    }, toAllTotal() {
      let total = 0
      let compose = []
      if (!(isEmpty(this.form.yclfPrice))) {
        total += parseFloat(this.form.yclfPrice)
        // compose += '原材料费' + parseFloat(this.form.yclfPrice)
        compose.push({
          label: '原材料费',
          unitPrice: this.form.yclfPrice
        })
      }
      if (!(isEmpty(this.form.gxfPrice))) {
        total += parseFloat(this.form.gxfPrice)
        //compose += '工序费' + parseFloat(this.form.gxfPrice)
        compose.push({
          label: '工序费',
          unitPrice: this.form.gxfPrice
        })
      }
      if (!(isEmpty(this.form.glfPrice))) {
        total += parseFloat(this.form.glfPrice)
        // compose += '管理费' + parseFloat(this.form.glfPrice)
        compose.push({
          label: '管理费',
          unitPrice: this.form.gxfPrice
        })

      }
      if (!(isEmpty(this.form.yfPrice))) {
        total += parseFloat(this.form.yfPrice)
        // compose += '运费' + parseFloat(this.form.yfPrice)

        compose.push({
          label: '运费',
          unitPrice: this.form.yfPrice
        })

      }
      if (!(isEmpty(this.form.sfPrice))) {
        total += parseFloat(this.form.sfPrice)
        // compose += '税费' + parseFloat(this.form.sfPrice)
        compose.push({
          label: '税费',
          unitPrice: this.form.sfPrice
        })

      }
      // this.form.enquiryOrigin.compose=compose
      // this.form.enquiryOrigin.price=total
      this.purchaseItem.dataSource[0].enquiryOrigin.compose = compose
      this.purchaseItem.dataSource[0].enquiryOrigin.price = total
      this.purchaseItem.dataSource[0].price = total
      this.purchaseItem.dataSource[0].enquiryOrigin.businessId = getCurBusinessId()
      this.form.total = total
    }
  }
})

</script>
<style lang="less" scoped>
.invoiceDetails {
  box-sizing: border-box;
  background-color: #ffffff;
}

.RequirementConfirmation {
  .header {
    &-title {
      color: #090b12;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 15px;

      .copy {
        font-size: 13px;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }

  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    position: relative;

    &:before {
      background: #FF6026;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 14px;
      color: #8d8e99;
    }
  }

  &-content {
    &-item {
      width: 100%;
      background: #fff;
      position: relative;

      .edit-btn {
        position: absolute;
        top: 4px;
        right: -15px;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep .ant-form-item-label {
    line-height: 22px;

    label {
      color: #626161 !important;
    }
  }

  ::v-deep .ant-form-item-control {
    line-height: 22px;
    color: #131212;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subTitle {
    width: calc(100% - 40px);
    font-size: 15px;
    color: #FF6026;
    padding-bottom: 8px;
    border-bottom: 1px solid #efefef;
    margin: 0 auto 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag-title {
      padding: 2px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background: #fff2ea;
      border-radius: 2px;
    }

    img {
      width: 15px;
      margin-right: 4px;
    }

    span {
      line-height: 22px;
      font-size: 14px;
    }
  }

  .basicInfo-form {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 18px 0 4px;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &-item {
      padding: 0 20px;
    }
  }

  ::v-deep .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-btn-link[disabled] img {
    filter: grayscale(100%);
  }

  .price-title {
    width: 107px;
    margin-top: 8px;
  }

  .process-content {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;

    ::v-deep .ant-timeline-item-tail {
      border: 0.5px dashed #dcdcdc;
    }

    ::v-deep .ant-timeline-item-head {
      width: 5px;
      height: 5px;
      background: #d7d7db;
      border: none;
      left: 2px;
      top: 3px;
    }

    .content {
      padding-top: 5px;
      color: #000000;
      font-size: 14px;
      line-height: 20px;
    }

    .time {
      font-size: 14px;
      line-height: 20px;
      color: #8d8e99;
    }
  }
}

.ant-descriptions {
  // background-color: #f7f8fa;
  // padding: 16px 25px 0;
  // border-radius: 8px;

  &.tag_2 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

::v-deep .ant-descriptions-item-label {
  color: #595959;
}

::v-deep .ant-descriptions-item-content {
  color: #131212;
}

::v-deep .ant-radio-disabled + span {
  color: #262626;
}

.card-view {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px 25px;

  &-item {
    width: calc((100% - 50px) / 3);
    height: 144px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px dashed #e8e9eb;
    padding: 15px;
    display: flex;
    position: relative;

    .cover {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        & + .option-area {
          opacity: 1;
          z-index: 10;
        }
      }
    }

    .option-area {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.65);
      position: absolute;
      left: 15px;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      z-index: -1;

      &:hover {
        opacity: 1;
        z-index: 10;
      }

      img {
        height: 16px;
        cursor: pointer;
      }

      .split-line {
        width: 1px;
        height: 14px;
        background-color: #fff;
        margin: 0 15px;
      }
    }
  }

  .info-card {
    &.inside {
      flex: 1 0 0;
      min-width: 0;
    }

    .sub_title {
      font-size: 14px;
      font-weight: 400;
      color: #131212;
      line-height: 22px;
      margin-bottom: 8px;
      overflow: hidden;
      .text-ellipsis-multi(2);
    }

    div.labels {
      font-size: 12px;
      color: #8d8e99;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.desc-title {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 12px;
  margin-bottom: 15px;

  span.title {
    font-size: 14px;
    color: #131212;
  }
}

div.file-card {
  width: max-content;
  height: 64px;
  background: #fff;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  margin-bottom: 8px;

  &-title {
    margin-bottom: 15px;

    img.icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .fileName {
      color: #1d2129;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-size {
    font-weight: 400;
    color: #8d8e99;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    padding-left: 22px;
  }
}

.coverBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.last-label {
  width: 100%;
  height: 102px;
  background-image: url('~@/assets/longerBG.png');
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  .ant-form-item {
    margin-bottom: 0;
  }

  ::v-deep .ant-input {
    border-color: #ff9a57;
  }

  ::v-deep .ant-input-suffix {
    color: #131212;
  }

  .moneyIcon {
    position: absolute;
    bottom: 0;
    right: 19px;
  }
}

.numberInput {
  width: 320px;
  border-color: #ff7d2f;
  height: 38px;
  line-height: 38px;

  ::v-deep .ant-input-number-input {
    font-size: 18px;
    color: #131212;
    font-weight: 500;
  }
}


@import '~@/styles/form.less';
@import '~@/styles/orderDetail.less';
.action {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 14px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  img {
    width: 20px;
    margin-right: 4px;
  }
}

::v-deep .ant-form-item-label {
  width: 130px !important;
  display: flex;
  text-align: right !important;
  justify-content: flex-end;
}

.sale-table {
  .quote-table {
    padding: 0 15px;
    overflow-x: auto;

    .ant-table-wrapper {
      width: 1056px;
    }

    ::v-deep .ant-table-thead > tr > th {
      background: #F4F5F7;
    }

    ::v-deep .ant-table-footer {
      height: 48px;
      padding: 0;

      .footer-info-bar {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 16px;
        box-sizing: border-box;

        .title {
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
        }

        .total {
          height: 100%;
          border-left: 1px solid #E8E9EB;
          width: 484px;
          line-height: 46px;
          padding-left: 16px;
          color: #FF6026;
          font-size: 16px;
        }
      }
    }
  }
}

::v-deep .ant-table-footer {
  //height: 56px !important;
  padding: 0px !important;
}

.orderno-tag {
  //padding: 9px 4px 9px 12px;
  //min-width: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 10px;
  background: #E8E9EB;
  border-radius: 3px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  margin-right: 4px;
}

.saleName {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #264AFF;
  /* 状态标签 */
  display: flex;
  flex-direction: row;
  //justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  background: #E2E7FB;
  border-radius: 3px;

}

.saleTitleGroup {
  display: flex;

}

.saleTitle, .salePart {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.85);
  background: #F4F5F7;
  border: 1px solid #E8E9EB;
  width: 100%;
  padding: 10px 16px;

  font-family: 'PingFang SC';
  font-style: normal;

  font-size: 14px;
  line-height: 150%;
  /* 文字色/标题+主要 */
  color: rgba(0, 0, 0, 0.85);
  border-right: 0px;
}

.saleTitle {
  font-weight: bold;
}

.salePart {
  background-color: #ffffff;
  border-top: 0px;
  border-right: 0px;
}

.sale-table {
  border: 1px solid #E8E9EB;
  margin-top: 24px;
}

.openingInvocie {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  padding-right: 16px;
}

.info-card {
  font-size: 16px;
  color: #131212;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.uploadCover {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .sale-table[data-v-cd4a0cfc] {
  border-radius: 4px;
}

::v-deep .ant-table table {
  border-radius: 4px;
}

.num-style {

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #1681FF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: rgba(22, 129, 255, 0.1);
  border-radius: 8px;
  width: 61px;
  height: 28px;
}

.cost-mun {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  display: flex;
  color: #262626;


}

::v-deep .ant-form-item-price label {

  color: #FF6026 !important;
}
</style>

