import { render, staticRenderFns } from "./addProduct.vue?vue&type=template&id=3a2bc7da&scoped=true"
import script from "./addProduct.vue?vue&type=script&lang=js"
export * from "./addProduct.vue?vue&type=script&lang=js"
import style0 from "./addProduct.vue?vue&type=style&index=0&id=3a2bc7da&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2bc7da",
  null
  
)

export default component.exports