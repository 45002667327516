import { render, staticRenderFns } from "./productCaseList.vue?vue&type=template&id=fe8ad398&scoped=true"
import script from "./productCaseList.vue?vue&type=script&lang=js"
export * from "./productCaseList.vue?vue&type=script&lang=js"
import style0 from "./productCaseList.vue?vue&type=style&index=0&id=fe8ad398&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe8ad398",
  null
  
)

export default component.exports