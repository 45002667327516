<!--
 * @Description: 报价详情 生产商
 * @Author: zhang zhen
 * @Date: 2023-07-27 15:33:45
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-20 17:44:36
 * @FilePath: /page-sass/src/views/quotePriceManagement/quotePriceDetails.vue
-->
<template>
  <div class="RequirementConfirmation">
    <div class="header">
      <div class="header-title">{{ baseInfo.purchaseTitle }}</div>
    </div>
    <div class="RequirementConfirmation-content">
      <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
        <h2 class="RequirementConfirmation-title"><span class="title">基本信息</span></h2>
        <a-descriptions :column="2" bordered style="margin-bottom: 25px;">
          <a-descriptions-item label="生产商参与数量">
            {{ baseInfo.participantCount || '-' }}
          </a-descriptions-item>

          <a-descriptions-item label="需求类型">
            {{ baseInfo.purchaseTypeDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="报价截止日期">
            {{ baseInfo.quotationDeadline || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="付款方式">
            {{ baseInfo.paymentMethodDictName || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <h2 class="RequirementConfirmation-title"><span class="title">采购商信息</span></h2>
        <a-descriptions :column="2" bordered style="margin-bottom: 25px;">
          <a-descriptions-item label="采购商名称" :span="2">
            {{ baseInfo.customerName || '个人' }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <!-- 生产商 -->
      <template>
        <div class="RequirementConfirmation-content-item" style="margin-top: 15px">
          <h2 class="RequirementConfirmation-title"><span class="title">详细要求</span></h2>
          <!-- v-if="formModel == 1" -->
          <template v-if="formModel == 1">
            <splitLine label="瓦楞纸包装" />
            <a-descriptions :column="2" bordered>
              <a-descriptions-item label="包装用途">
                {{ baseInfo.wrapTypeDictName || '-' }}
              </a-descriptions-item>

              <a-descriptions-item label="瓦楞箱型">
                {{ baseInfo.purchaseTypeDictName || '-' }}
              </a-descriptions-item>
            </a-descriptions>
            <splitLine label="上传文件" />
            <div class="excel-box">
              <div class="top-box">
                <img class="excel-icon" src="~@/assets/excel.png" alt="" />
                <!-- <span class="fileName">{{ excelName }}</span> -->
                <a @click="handleDownLoadFile(baseInfo.templateFile)">下载</a>
              </div>
            </div>
            <splitLine label="附件" />
            <div class="files-list">
              <div class="files-list-item" v-for="item in fileList" :key="item.name">
                <div class="cover">
                  <img :src="item.url" alt="" class="cover" v-if="item.isImage" />
                  <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
                </div>
                <div class="option-area">
                  <img src="~@/assets/view.png" alt="" title="预览" @click="handleDownLoadFile(item.url)" />
                  <div class="split-line"></div>
                  <img src="~@/assets/dowload.png" alt="" title="下载" @click="handleDownLoadFile(item.url)" />
                </div>
                <div class="info-card inside">
                  <div class="sub_title">{{ item.fileName }}</div>
                  <!-- <div class="labels">文件大小: -K</div>
                            <div class="labels">上传日期: -</div> -->
                </div>
              </div>
            </div>

          </template>
          <template v-else>
            <!-- <a-button type="link" class="edit-btn" @click="handleEdit('CompleteInformation')"
              :disabled="!purchaseItem.length || infoStatus == '2'" v-if="isMine == 1 && !isOut"><img
                src="~@/assets/edit.png" /><span>编辑</span></a-button> -->
            <a-tabs type="card" @change="toTab">
              <a-tab-pane key="1" :tab="tabTitleList[index] ? tabTitleList[index] : `散件${index + 1}`"
                          v-for="(i, index) in purchaseItem" :key="index">
                <RequirementInfoView :formItemDtoList="i.formInfo" :purchasePrice="i.purchasePrice" />
                <splitLine label="附件" v-show="i.fileList && i.fileList.length" />
                <div class="files-list">
                  <div class="files-list-item" v-for="item in i.fileList" :key="item.name">
                    <div class="cover">
                      <img :src="item.url" alt="" class="cover" v-if="item.isImage" />
                      <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
                    </div>
                    <div class="option-area">
                      <img src="~@/assets/view.png" alt="" title="预览" @click="handleDownLoadFile(item.url)" />
                      <div class="split-line"></div>
                      <img src="~@/assets/dowload.png" alt="" title="下载" @click="handleDownLoadFile(item.url)" />
                    </div>
                    <div class="info-card inside">
                      <div class="sub_title">{{ item.fileName }}</div>
                      <!-- <div class="labels">文件大小: -K</div>
                            <div class="labels">上传日期: -</div> -->
                    </div>
                  </div>
                </div>
                <splitLine label="报价单" iconPath="/last-quote.png" v-if="!isView" />

                <div class="quote-table" v-if="!isView ">
                  <!--询价方式start-->
                  <a-form-model-item
                    v-if="i.dataSource[0].purchaserInquiryMode ==1 ||i.dataSource[0].purchaserInquiryMode ==2"
                    label="询价方式"
                    :wrapper-col="{ span: 20 }"
                    :rules="{
	                        required: true,
	                        message: `请选择询价方式`,
	                      }"
                    style="display: flex;white-space: normal"
                  >
                    <a-radio-group v-model="i.quoteMode" :default-value="1"
                                   @change="changeEnquiryType($event,index)">
                      <a-radio :value="1">
                        常规报价
                      </a-radio>
                      <a-radio :value="2">
                        阶梯报价
                      </a-radio>
                    </a-radio-group>
                  </a-form-model-item>

                  <!--询价方式end-->
                  <!--采购商预算价table  显示start-->
                  <!--  常规-->
                  <div
                    v-if="i.dataSource[0].purchaserInquiryMode==1 && (i.dataSource[0].quoteMode==1 ||i.dataSource[0].quoteMode==2) "
                    style="display: flex;padding-bottom: 12px">
                    <a-form-model-item
                      label="采购商预算价"
                      :label-col="{ span: 2 }"
                      :wrapper-col="{ span: 20 }"
                      style="display: flex;white-space: normal;width: 1500px"
                      class="lable-content"
                    >
                      <a-table :columns="columnsPurchaser" :pagination="false" :data-source="i.dataSource" bordered>
                        <template slot="prePrice" slot-scope="text, record, index">
                        <span v-if="text">
                           {{ text }}元/{{ record.skuUnit }}
                        </span>
                          <span v-else style="color: #EE4261">
                           由生产商报价
                        </span>
                        </template>
                        <template slot="total" slot-scope="text, record, index">
                          <span
                            style="color: #FF6026;font-weight: 600"> {{ multiply((record.num || 0), (record.prePrice || 0))
                            }}</span>
                        </template>
                      </a-table>
                    </a-form-model-item>
                  </div>
                  <!-- 阶梯-->
                  <div v-if="i.dataSource[0].purchaserInquiryMode==2  "
                       style="display: flex;padding-bottom: 12px;">
                    <a-form-model-item
                      label="采购商预算价"
                      :label-col="{ span: 2 }"
                      :wrapper-col="{ span: 20 }"
                      style="display: flex;white-space: normal;width: 1500px"
                      class="lable-content"
                    >
                      <a-table :columns="columnsPurchaserLadders" :pagination="false"
                               :data-source="i.dataSource[0].preEnquiryLadders" bordered>
                        <template slot="num" slot-scope="text, record, index">
                           <span
                             v-if="index==0 && i.dataSource[0].preEnquiryLadders[index].intervalNumber&& i.dataSource[0].preEnquiryLadders.length>1">
                              {{ i.dataSource[0].preEnquiryLadders[index].intervalNumber
                             }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ i.dataSource[0].preEnquiryLadders[index + 1].intervalNumber - 1
                             }}
                            </span>
                          <span
                            v-if="index==1 && i.dataSource[0].preEnquiryLadders[index].intervalNumber && i.dataSource[0].preEnquiryLadders.length==2">
                              ≥&nbsp;&nbsp;{{ i.dataSource[0].preEnquiryLadders[index].intervalNumber }}
                            </span>
                          <span
                            v-else-if="index>=1 && index!=i.dataSource[0].preEnquiryLadders.length-1 && i.dataSource[0].preEnquiryLadders[index].intervalNumber ">
                              {{ i.dataSource[0].preEnquiryLadders[index].intervalNumber
                            }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ i.dataSource[0].preEnquiryLadders[index + 1].intervalNumber - 1
                            }}
                            </span>
                          <span
                            v-else-if="index==i.dataSource[0].preEnquiryLadders.length-1 && i.dataSource[0].preEnquiryLadders[index].intervalNumber  ">
                              ≥&nbsp;&nbsp;{{ i.dataSource[0].preEnquiryLadders[index].intervalNumber }}
                            </span>
                          <span v-else></span>
                          <!--                        <span  >≥ {{ record.intervalNumber}}</span>-->
                        </template>
                        <template slot="prePrice" slot-scope="text, record, index">
                          <span>{{ record.unitPrice }}元/{{ record.skuUnit }}</span>
                        </template>
                      </a-table>
                    </a-form-model-item>
                  </div>

                  <div v-if="i.dataSource[0].purchaserInquiryMode==3 "
                       style="display: flex;padding-bottom: 12px;">
                    <a-form-model-item
                      label="采购商预算价"
                      :label-col="{ span: 2 }"
                      :wrapper-col="{ span: 20 }"
                      style="display: flex;white-space: normal;width: 100%;"
                      class="lable-content"
                    >
                      <a-table :columns="columnsPurchaserCost" :pagination="false" :data-source="i.dataSource" bordered>
                        <template slot="prePrice" slot-scope="text, record, index">
                          {{ record.preEnquiryOrigin.price || '由生产商报价' }}
                        </template>
                        <template slot="preEnquiryOrigin" slot-scope="text, record, index">
                          {{ record.preEnquiryOrigin.compose.replace(/,/g, '+') }}
                        </template>
                        <template slot="total" slot-scope="text, record, index">
                          <span
                            style="color: #FF6026;font-weight: 600">{{ multiply((record.num || 0), (record.preEnquiryOrigin.price || 0))
                            }}</span>
                        </template>
                      </a-table>
                    </a-form-model-item>
                  </div>
                  <!--采购商预算价table 显示end-->
                  <!--  需求 未报价 和重新报价 能编写的时候-->
                  <div v-if="(purchaseData!=null || !quoteList.length) && i.dataSource[0].isEdit==true">
                    <!-- 生产商填写 table  常规询价 未报价 默认常规 -->
                    <div v-if="i.dataSource[0].purchaserInquiryMode==1 && i.dataSource[0].quoteMode==1"
                         style="display: flex;">
                      <a-form-model-item
                        label="我的报价"
                        :label-col="{ span: 2 }"
                        :wrapper-col="{ span: 20 }"
                        style="display: flex;white-space: normal;flex-direction: row;"
                        class="lable-content"
                      >
                      </a-form-model-item>
                      <a-descriptions layout="vertical" bordered style="width: 1056px;" >
                        <a-descriptions-item style="width: 280px;color:rgba(0, 0, 0, 0.85)!important;;font-weight: 500!important;" label="常规报价" >
                          <a-input-number min="0" v-model="i.dataSource[0].price" style="width: 320px;"
                                          placeholder="请输入"
                                          @change="handleChangeInput($event, i.dataSource[0])"
                                          @keyup.enter.native="handleEditRow(i.dataSource[0], false)" />
                          <span style="padding-left: 10px">元/{{ i.dataSource[0].skuUnit }}</span>
                        </a-descriptions-item>
                        <a-descriptions-item style="width: 280px;color:rgba(0, 0, 0, 0.85)!important;;font-weight: 500!important;  " label="合计(元)">
                          <span style="color: #FF6026;font-weight: 600"
                                v-if="i.dataSource[0].price"> {{ multiply((i.dataSource[0].num || 0), (i.dataSource[0].price || 0))
                            }}</span>
                          <span style="color: #FF6026;font-weight: 600" v-else>-</span>
                        </a-descriptions-item>
                      </a-descriptions>

                    </div>
                    <!--   常规询价 未报价 阶梯报价 -->
                    <div v-if="i.dataSource[0].purchaserInquiryMode==1 && i.dataSource[0].quoteMode==2"
                         style="display: flex">
                      <a-form-model-item
                        label="我的报价"
                        :label-col="{ span: 2 }"
                        :wrapper-col="{ span: 20 }"
                        class="lable-content"
                        style="display: flex;white-space: normal;width: 1500px"
                      >
                        <a-table size="middle" :columns="columnsPurchaserLadder" :pagination="false"
                                 :data-source="i.dataSource[0].enquiryLadders||[]" bordered
                                 class="custom-table-border">
                          <template slot="intervalNumber" slot-scope="text, record, itemIndex">
                            <span style="padding: 0px 8px">≥</span>
                            <a-input-number :defaultValue="text" placeholder="请输入"
                                            style="width: 120px;" type="number"
                                            :min="itemIndex>0?parseInt(i.dataSource[0].enquiryLadders[itemIndex-1].intervalNumber)+1:0"
                                            @change="onIntervalNumberChange($event,i,itemIndex)"></a-input-number>
                          </template>
                          <template slot="quotIntervalNumber" slot-scope="text, record, itemIndex">
                               {{ record.intervalNumber }}
                            <span v-if="itemIndex<=i.dataSource[0].enquiryLadders.length-1&&i.dataSource[0].enquiryLadders[itemIndex+1]&&i.dataSource[0].enquiryLadders[itemIndex+1].intervalNumber"> ~ {{ i.dataSource[0].enquiryLadders[itemIndex+1].intervalNumber-1}}</span>
                          </template>
                          <template slot="unitPrice" slot-scope="text, record, itemIndex">
                            <a-input-number min="0" :defaultValue="text" placeholder="请输入"
                                            style="width: 120px;" type="number"
                                            @change="onUnitPriceChange($event,i,itemIndex)"></a-input-number>
                            <span style="padding-left: 10px">元/{{ i.dataSource[0].skuUnit }}</span>
                          </template>
                          <template slot="maxCount" slot-scope="text, record, itemIndex">
                            <span style="color: #FF6026" @click="showBDelete(index,itemIndex)">删除</span>
                          </template>
                          <template slot="footer" slot-scope="">

                            <div style="display: flex;padding:10px 16px;justify-content: center;align-items: center">
                              <img src="@/assets/add-ladder.png" style="width: 16px;height: 16px">
                              <span style="color: #FF6026" @click="toAddLadder(index)">新增加个区间</span>
                              <span style="color:#919191">（可设置不超过4个区间价格）</span>
                            </div>
                          </template>
                        </a-table>
                      </a-form-model-item>
                    </div>
                    <!--   常规阶梯询价  阶梯询价的常规报价  未报价 默认-->
                    <div v-if="i.dataSource[0].purchaserInquiryMode==2 && i.dataSource[0].quoteMode==1">
                      <a-form-model-item
                        label="常规报价"
                        :label-col="{ span: 2 }"
                        :wrapper-col="{ span: 20 }"
                        style="display: flex;white-space: normal;width: 1100px;align-items: center;color: rgba(0, 0, 0, 0.85) !important;font-weight: 500;"
                        class="desc-item-lable"
                        :rules="{
	                        required: true,
	                        message: `请输入常规报价`,
	                      }"
                      >
                        <a-input-number min="0" v-model="i.dataSource[0].price" style="width: 320px;"
                                        placeholder="请输入"
                                        @change="handleChangeInput($event, i.dataSource[0])"
                                        @keyup.enter.native="handleEditRow(i.dataSource[0], false)" />
                        <span style="padding-left: 10px;">元/{{ i.dataSource[0].skuUnit }}</span>
                        <!--                        <a-table :columns="columnsjt" :pagination="false"-->
                        <!--                                 :data-source="i.dataSource[0].preEnquiryLadders"-->
                        <!--                                 bordered>-->

                        <!--                          <template slot="minCount" slot-scope="text, record, index">-->
                        <!--                            数量≥{{ record.intervalNumber }}-->

                        <!--                          </template>-->

                        <!--                          <template slot="enquiryLadders" slot-scope="text, record, index">-->
                        <!--                            <a-input v-model="record.price" style="width: 320px;" placeholder="请输入"-->
                        <!--                                     @input="handleChangeInput($event, record)"-->
                        <!--                                     @keyup.enter.native="handleEditRow(record, false)" />-->
                        <!--                            <span style="padding-left: 20px;">元/{{ record.skuUnit }}</span>-->
                        <!--                          </template>-->
                        <!--                          <template slot="total" slot-scope="text, record, index">-->
                        <!--                            - -->
                        <!--                          </template>-->
                        <!--                          <template slot="footer" slot-scope="currentPageData">-->
                        <!--                            <div class="footer-info-bar">-->
                        <!--                              <span class="title">合计（元）</span>-->
                        <!--                              <div class="total">-->
                        <!--                                - -->
                        <!--                              </div>-->
                        <!--                            </div>-->
                        <!--                          </template>-->
                        <!--                        </a-table>-->
                      </a-form-model-item>

                    </div>
                    <!--阶梯询价的阶梯报价-->
                    <div v-if="i.dataSource[0].purchaserInquiryMode==2 && i.dataSource[0].quoteMode==2"
                         style="display: flex">
                      <a-form-model-item
                        label="我的报价"
                        :label-col="{ span: 2 }"
                        :wrapper-col="{ span: 20 }"
                        class="lable-content"
                        style="display: flex;white-space: normal;width: 1500px"
                      >
                        <a-table size="middle" :columns="columnsPurchaserLadder" :pagination="false"
                                 :data-source="i.dataSource[0].enquiryLadders||[]" bordered
                                 class="custom-table-border">
                          <template slot="intervalNumber" slot-scope="text, record, itemIndex">
                            <span style="padding: 0px 8px">≥</span>
                            <a-input-number :defaultValue="text" placeholder="请输入"
                                            style="width: 120px;" type="number"
                                            :min="itemIndex>0?parseInt(i.dataSource[0].enquiryLadders[itemIndex-1].intervalNumber)+1:0"
                                            @change="onIntervalNumberChange($event,i,itemIndex)"></a-input-number>
                          </template>
                          <template slot="quotIntervalNumber" slot-scope="text, record, itemIndex">
                            {{ record.intervalNumber }}
                            <span v-if="itemIndex<=i.dataSource[0].enquiryLadders.length-1&&i.dataSource[0].enquiryLadders[itemIndex+1]&&i.dataSource[0].enquiryLadders[itemIndex+1].intervalNumber"> ~ {{ i.dataSource[0].enquiryLadders[itemIndex+1].intervalNumber-1}}</span>
                            <!--                            <span-->
<!--                              v-if="itemIndex==0 && i.dataSource[0].enquiryLadders[itemIndex].intervalNumber&& i.dataSource[0].enquiryLadders.length>1">-->
<!--                              <span v-if="i.dataSource[0].enquiryLadders[itemIndex+1].intervalNumber-1">-->
<!--                                 {{ i.dataSource[0].enquiryLadders[itemIndex].intervalNumber-->
<!--                                }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ i.dataSource[0].enquiryLadders[itemIndex + 1].intervalNumber - 1-->
<!--                                }}-->
<!--                              </span>-->
<!--                              <span v-else>-->
<!--                                ≥&nbsp;&nbsp;{{ i.dataSource[0].enquiryLadders[itemIndex].intervalNumber }}-->
<!--                              </span>-->

<!--                            </span>-->
<!--                            <span-->
<!--                              v-if="itemIndex==1 && i.dataSource[0].enquiryLadders[itemIndex].intervalNumber && i.dataSource[0].enquiryLadders.length==2">-->
<!--                              ≥&nbsp;&nbsp;{{ i.dataSource[0].enquiryLadders[itemIndex].intervalNumber }}-->
<!--                            </span>-->
<!--                            <span-->
<!--                              v-else-if="itemIndex>=1 && itemIndex!=i.dataSource[0].enquiryLadders.length-1 && i.dataSource[0].enquiryLadders[itemIndex].intervalNumber ">-->
<!--                             <span v-if="i.dataSource[0].enquiryLadders[itemIndex+1].intervalNumber-1">-->
<!--                                 {{ i.dataSource[0].enquiryLadders[itemIndex].intervalNumber-->
<!--                               }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ i.dataSource[0].enquiryLadders[itemIndex + 1].intervalNumber - 1-->
<!--                               }}-->
<!--                              </span>-->
<!--                              <span v-else>-->
<!--                                ≥&nbsp;&nbsp;{{ i.dataSource[0].enquiryLadders[itemIndex].intervalNumber }}-->
<!--                              </span>-->
<!--                            </span>-->
<!--                            <span-->
<!--                              v-else-if="itemIndex==i.dataSource[0].enquiryLadders.length-1 && i.dataSource[0].enquiryLadders[itemIndex].intervalNumber  ">-->
<!--                              ≥&nbsp;&nbsp;{{ i.dataSource[0].enquiryLadders[itemIndex].intervalNumber }}-->
<!--                            </span>-->
<!--                            <span v-else></span>-->
                          </template>

                          <template slot="unitPrice" slot-scope="text, record, itemIndex">
                            <a-input-number min="0" :defaultValue="text" placeholder="请输入"
                                            style="width: 120px;" type="number"
                                            @change="onUnitPriceChange($event,i,itemIndex)"></a-input-number>
                            元/{{ i.dataSource[0].skuUnit }}
                          </template>
                          <template slot="maxCount" slot-scope="text, record, itemIndex">
                            <span style="color: #FF6026" @click="showBDelete(index,itemIndex)">删除</span>
                          </template>
                          <template slot="footer" slot-scope="">
                            <div style="display: flex;padding:10px 16px;justify-content: center;align-items: center">
                              <img src="@/assets/add-ladder.png" style="width: 16px;height: 16px">
                              <span style="color: #FF6026" @click="toAddLadder(index)">新增加个区间</span>
                              <span style="color:#919191">（可设置不超过4个区间价格）</span>
                            </div>
                          </template>
                        </a-table>
                      </a-form-model-item>
                    </div>
                    <!--   成本阶梯 未报价 默认-->
                    <div v-if="i.dataSource[0].purchaserInquiryMode==3" style="display: flex">
                      <a-form-model-item
                        label="我的报价"
                        :label-col="{ span: 2 }"
                        :wrapper-col="{ span: 20 }"
                        style="display: flex;white-space: normal;flex-direction: row"
                        class="lable-content"
                      >
                      </a-form-model-item>
                      <a-descriptions layout="vertical" bordered style="width: 1056px;">
                        <a-descriptions-item style="width: 280px" :label="item.name"
                                             v-for="(item,index1) in i.dataSource[0].preEnquiryOrigin.composesList"
                                             :key="index1">
                          <a-input-number min="0" placeholder="请输入" v-model="item.unitPrice" style="width: 160px;"
                                          @change="toAllTotal(index)"></a-input-number>
                          <span style="padding-left: 10px;">元/{{ i.dataSource[0].skuUnit }}</span>
                        </a-descriptions-item>
                        <a-descriptions-item style="width: 280px" label="合计(元)">
                          <span style="color: #FF6026;font-weight: 600" v-if=" form.total[index]">
                              {{ multiply((i.dataSource[0].num || 0), (form.total[index] || 0)) }}</span>
                          <span style="color: #FF6026;font-weight: 600" v-else>-</span>
                        </a-descriptions-item>
                      </a-descriptions>
                    </div>
                  </div>


                </div>
              </a-tab-pane>
            </a-tabs>
          </template>
        </div>
      </template>
    </div>
    <div class="action-button-area" v-if="!isView">
      <a-button type="primary" @click="handleQuote" :loading="loading">报价</a-button>
    </div>
    <h2 class="RequirementConfirmation-title" style="margin-top: 15px;"><span class="title">历史报价信息</span></h2>
    <EmptyArea v-if="!quoteList.length" description="暂无报价信息" />
    <div style="width: 1073px">

      <template v-for="(item, index) in quoteList.slice(0,!quoteItem.expand?1:quoteList.length)">

        <splitLine v-if="item.quoteDesc=='最终报价'" :label="`${item.quoteDesc}`" iconPath="/last-quote.png" />
        <splitLine v-else :label="`${item.quoteDesc}`" iconPath="/quotePrice.png" />
        <QuotePriceTable :quoteDesc="item.quoteDesc" :class=" index!=0 && 'less-info '"
                         :dataSource="item.quoteHistoryItemVos"
                         :total="item.allTotal" :purchaserInquiryMode="purchaserInquiryMode"
                         :quoteStatus="item.quoteStatus" :quoteIndex="index" />
      </template>
      <div class="more-history" @click="onExpand(quoteItem)" v-if="quoteList.length>1 && quoteList!=undefined">
        {{ quoteItem.expand ? '收起历史报价信息' : '展开历史报价信息' }}
        <img v-if="!quoteItem.expand" src="@/assets/expand-history.png"
             style="width: 12px;height: 12px;margin-left: 2px;">

        <img v-if="quoteItem.expand" src="@/assets/close-history.png"
             style="width: 12px;height: 12px;margin-left: 2px;">

      </div>
    </div>
    <div class="action-button-area" v-if="isView">
      <a-button @click="handleBack">返回</a-button>
      <!--      <a-button @click="handleOk" class="button-area" type="primary">确认合作数量</a-button>-->
    </div>

    <StairsQuoteModel ref="stairsQuoteModel" @ok="toStairsQuote"></StairsQuoteModel>
    <CostQuoteModel ref="costQuoteModel" @ok="costQuoteOk"></CostQuoteModel>

    <a-modal
      title="温馨提示"
      :width="600"
      :visible="showB"
      okText="确认"
      centered
      @ok="onDeleteLadder"
      @cancel="showB = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>确定删除此报价信息？</span>
      </div>
    </a-modal>
    <!--    <Cooperation ref="cooperation"></Cooperation>-->
  </div>
</template>

<script>
import { postAction, postQueryAction, getAction } from '@/api/manage'
import DetailsInfo from './modules/detailsInfo.vue'
import { mapGetters } from 'vuex'
import ExcelUploader from '@/views/PublishingRequirements/modules/uploadFIle.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import RequirementInfoView from '@/components/plugins/RequirementInfoView.vue'
import QuotePriceTable from '@/components/plugins/quotePriceTable.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import StairsQuoteModel from '@/views/quotePriceManagement/modules/stairsQuoteModel.vue'
import CostQuoteModel from '@/views/quotePriceManagement/modules/costQuoteModel.vue'
import { isEmpty } from 'lodash'
import { getCurBusinessId } from '@/util/utils'

// import Cooperation from '@/views/quotePriceManagement/modules/cooperation.vue'

export default {
  name: 'quotePriceDetails',
  components: {
    DetailsInfo,
    ExcelUploader,
    JDictSelect,
    splitLine,
    RequirementInfoView,
    QuotePriceTable,
    EmptyArea,
    StairsQuoteModel,
    CostQuoteModel
    // Cooperation
  },
  data() {
    return {
      showB: false,
      showStep: true,
      purchaseId: null,
      baseInfo: {},
      basicInfo: {},
      purchaseItem: [],
      loading: false,
      quoteList: [],
      fileList: [],
      formInfo: {},
      form: { price: [], total: [] }, // 其他信息字段
      layoutInfo: {
        wrapperCol: { span: 12 },
        labelCol: { span: 12 }
      },
      columnsPurchaserLadder: [
        {
          title: <span style={ { color: '#131212' } }>设置阶梯报价数量</span>,
          dataIndex: 'intervalNumber',
          width: 100,
          scopedSlots: { customRender: 'intervalNumber' }
        }
        ,
        {
          title: '阶梯报价数量',
          dataIndex: 'quotIntervalNumber',
          width: 150,
          scopedSlots: { customRender: 'quotIntervalNumber' }
        },
        {
          title: '报价单价（元）',
          dataIndex: 'unitPrice',
          width: 100,
          scopedSlots: { customRender: 'unitPrice' }
        },
        {
          title: '操作',
          dataIndex: 'maxCount',
          width: 100,
          scopedSlots: { customRender: 'maxCount' }
        }

      ],
      columnsPurchaserLadders: [
        {
          title: '阶梯报价数量',
          dataIndex: 'num',
          width: 200,
          scopedSlots: { customRender: 'num' }
        },
        {
          title: '预算单价',
          dataIndex: 'prePrice',
          scopedSlots: { customRender: 'prePrice' },
          width: 200
        }

      ],
      columnsPurchaser: [
        {
          title: '阶梯报价数量',
          dataIndex: 'num',
          width: 150
        },
        {
          title: '预算单价',
          dataIndex: 'prePrice',
          scopedSlots: { customRender: 'prePrice' },
          width: 150
        },
        {
          title: '预算合计(元)',
          dataIndex: 'total',
          width: 107,
          scopedSlots: { customRender: 'total' }
        }
      ],
      columnsPurchaserCost: [
        {
          title: '阶梯报价数量',
          dataIndex: 'num',
          width: 150
        },
        {
          title: '预算单价',
          dataIndex: 'prePrice',
          scopedSlots: { customRender: 'prePrice' },
          width: 107,

        },
        {
          title: '单价构成',
          dataIndex: 'preEnquiryOrigin',
          width: 207,
          scopedSlots: { customRender: 'preEnquiryOrigin' }
        },
        {
          title: '预算合计(元)',
          dataIndex: 'total',
          width: 100,
          scopedSlots: { customRender: 'total' }
        }
      ],
      columnsCost: [
        {
          title: '散件询价数量',
          dataIndex: 'num',
          width: 266
        },
        {
          title: '散件单位',
          dataIndex: 'skuUnit',
          width: 100
        },
        {
          title: '采购商-预算单价（元）',
          dataIndex: 'prePrice',
          scopedSlots: { customRender: 'prePrice' }

        },
        {
          title: '报价单价构成（元）',
          dataIndex: 'preEnquiryOrigin',
          width: 307,
          scopedSlots: { customRender: 'preEnquiryOrigin' }
        },
        {
          title: '报价合计（元）',
          dataIndex: 'total',
          width: 177,
          scopedSlots: { customRender: 'total' }
        }
      ]
      , columnsMode: [
        {
          title: '散件询价数量',
          dataIndex: 'num',
          width: 200
        },
        {
          title: '散件单位',
          dataIndex: 'skuUnit',
          width: 100
        },
        {
          title: '采购商-预算单价（元）',
          dataIndex: 'prePrice',
          scopedSlots: { customRender: 'prePrice' },
          width: 200
        },
        {
          title: '报价单价（元）',
          dataIndex: 'price',
          width: 150,
          scopedSlots: { customRender: 'price' }
        }, {
          title: '阶梯报价范围',
          dataIndex: 'enquiryLadders',
          width: 280,
          scopedSlots: { customRender: 'enquiryLadders' }
        },
        {
          title: '报价合计（元）',
          dataIndex: 'total',
          width: 107,
          scopedSlots: { customRender: 'total' }
        }
      ],
      columnsModeLadder: [
        {
          title: '散件询价数量',
          dataIndex: 'preEnquiryLadders',
          scopedSlots: { customRender: 'preEnquiryLadders' },
          width: 197
        },
        {
          title: '散件单位',
          dataIndex: 'skuUnit',
          width: 100
        },
        {
          title: '采购商-预算单价（元）',
          dataIndex: 'prePrice',
          scopedSlots: { customRender: 'prePrice' },
          width: 200
        },
        {
          title: '报价单价（元）',
          dataIndex: 'price',
          width: 150,
          scopedSlots: { customRender: 'price' }
        }, {
          title: '阶梯报价范围',
          dataIndex: 'enquiryLadders',
          width: 280,
          scopedSlots: { customRender: 'enquiryLadders' }
        },
        {
          title: '报价合计（元）',
          dataIndex: 'total',
          width: 107,
          scopedSlots: { customRender: 'total' }
        }
      ],
      columnsjt: [
        {
          title: '阶梯报价数量',
          dataIndex: 'minCount',
          scopedSlots: { customRender: 'minCount', minCompare: 'minCompare' },
          width: 266
        },

        {
          title: '报价单价（元）',
          dataIndex: 'enquiryLadders',
          width: 307,
          scopedSlots: { customRender: 'enquiryLadders' }
        },
        {
          title: '报价合计（元）',
          dataIndex: 'total',
          width: 177,
          scopedSlots: { customRender: 'total' }
        }
      ],
      columns: [
        {
          title: '散件询价数量',
          dataIndex: 'num',
          width: 266
        },

        {
          title: '报价单价（元）',
          dataIndex: 'price',
          width: 307,
          scopedSlots: { customRender: 'price' }
        },
        {
          title: '报价合计（元）',
          dataIndex: 'total',
          width: 177,
          scopedSlots: { customRender: 'total' }
        }
      ],
      formModel: 2,
      showFile: true,
      quoteType: '1',
      quoteId: null,
      tabTitleList: [],
      isView: false,
      quoteMode: 1, //1 为常规 2 为阶梯， 3为成本
      purchaserInquiryMode: 1,//1 为常规 2 为阶梯， 3为成本
      currtDate: [
        { num: '0<数量<1000', unit: '个', prePrice: 10, total: 20 },
        { num: '1000≤数量<2000', unit: '个', prePrice: 10, total: 20 },
        { num: '2000≤数量<3000', unit: '个', prePrice: 10, total: 20 }
      ],
      costDate: [
        { num: 233, unit: '个', prePrice: 10, price: '原材料费+工序费+税费', total: 20 },
        { num: 22, unit: '个', prePrice: 10, price: '原材料费+工序费+税费', total: 20 },
        { num: 443, unit: '个', prePrice: 10, price: '原材料费+工序费+税费', total: 20 }
      ],
      purchaseData: {},
      piQuotesLadderVOs: [],
      quoteInquiryMode: 1,
      quoteData: {},
      quoteLadderList: [],
      quoteItem: { expand: false },
      tabIndex: 0,
      quoteStatus: null,
      costCombList: [],
      quoteIndex: null,
      ladderIndex: null

    }
  },
  created() {
    const { id, quoteSource, quoteId, isView } = this.$route.query
    if (id) {
      this.purchaseId = id
      quoteId && (this.quoteId = quoteId)
      this.handleLoadInfo()
      this.quoteType = quoteSource || '1'
      this.isView = !!isView
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    onIntervalNumberChange(e, i, index) {
      this.formIndex = index
      i.dataSource[0].enquiryLadders[this.formIndex].intervalNumber = e
      console.log(i.dataSource[0].enquiryLadders[this.formIndex].intervalNumber)
      this.$forceUpdate()
    },
    onUnitPriceChange(e, i, index) {
      this.formIndex = index
      i.dataSource[0].enquiryLadders[this.formIndex].unitPrice = e
      this.$forceUpdate()
    },
    handleBack() {
      this.$router.go(-1)
    },
    multiply(a, b) {
      const precision = this.getPrecision(a) + this.getPrecision(b)
      const multiplier = Math.pow(10, precision)
      return (Math.round(a * multiplier) * Math.round(b * multiplier)) / (multiplier * multiplier)
    },
    getPrecision(num) {
      const str = String(num)
      const decimalIndex = str.indexOf('.')
      return decimalIndex === -1 ? 0 : str.length - decimalIndex - 1
    },
    handleChangeModel({ fileUrl, fileId }) {
      this.formInfo.file = fileUrl
    },
    handleEditRow(data, edit = true) {
      this.$set(data, 'isEdit', edit)
    },
    handleChangeInput(e, item) {
      // 获取输入的原始值
      let inputValue = e

      // // 替换非数字字符（只保留数字和一个小数点）
      // inputValue = inputValue.replace(/[^\d.]/g, '').replace(/(\..*?)\..*/g, '$1')
      //
      // // 如果存在小数点，确保小数点后最多四位
      // if (inputValue.includes('.')) {
      //   inputValue = inputValue.replace(/(\.\d{4})./g, '$1')
      // }

      // 如果需要禁止负数（可选）
      if (parseFloat(inputValue) < 0) {
        inputValue = inputValue.replace('-', '') // 移除负号
        // 或者你可以选择设置inputValue为0或某个默认值
      }
      if (item.purchaserInquiryMode == 2 && item.quoteMode == 1) {
        if (item.preEnquiryLadders) {
          item.preEnquiryLadders.map(item1 => {
            item1.price = inputValue
          })
        }
      } else {
        item.price = inputValue
      }
      this.$forceUpdate()
    },
    handleLoadInfo() {
      postAction(`/v2/purchases/showPurList/${ this.purchaseId }`).then((res) => {
        const { success, data, message } = res
        if (success) {
          const { itemList } = data
          !itemList || itemList.length == 0 && (this.formModel = 1)

          postAction('/quote/history', {
            businessId: this.setUserInfo().businessId,
            purchaseId: this.purchaseId,
            status: 0
          }).then(res => {
            const { success, data } = res
            if (success) {
              // this.quoteList = data && data.reverse() || []
              this.quoteList = data || []
              //将需求的报价方式取出
              console.log('this.purchaseItem')
              console.log(this.purchaseItem)
              this.quoteList.map(item => {
                item.quoteHistoryItemVos.map(item1 => {
                  let filterPurchase = this.purchaseItem.filter(item2 => item1.purchaseItemId == item2.dataSource[0].purchaseItemId)
                  item1.purchaserInquiryMode = filterPurchase[0].dataSource[0].purchaserInquiryMode
                })
              })
            }
          })
          postQueryAction('/business/queryByBusinessId', {
            businessId: data.businessId
          }).then(res => {
            const { success, data } = res
            if (success) {
              const { capacity, supplierPurchasePlan = {}, tradeIdentity = {}, customerName } = data
              this.basicInfo = Object.assign({ businessName: customerName }, tradeIdentity == 0 ? supplierPurchasePlan : capacity)
            }
          })

          if (data.uploadFile) {
            let fileList = data.uploadFile.split(',')
            fileList.map((i) => {
              let fileName = i.includes('/images/') ? i.split('/images/')[1] : i.split('/file/')[1]
              this.fileList.push({
                fileName,
                url: i,
                isImage: fileName.includes('.jpg') || fileName.includes('.png') || fileName.includes('.jpeg')
              })
            })
          }
          this.priceBudget = 0
          this.purchaseItem = []
          let dataSource = []

          itemList.map(i => {

            const { wrapTypeDictName } = i
            this.tabTitleList.push(`${ data.purchaseTypeDictName || '' } - ${ wrapTypeDictName || '' }`)
            // 计算合计金额
            let num = 0, price = 0, skuUnit = null
            let targetNumItem = i.itemForms.find(item => item.fieldFlag == 'num')
            let targetPriceItem = i.itemForms.find(item => item.fieldFlag == 'price')
            let targetSkuUnitItem = i.itemForms.find(item => item.fieldFlag == 'unit')
            let enquiryLadders = i.enquiryLadders
            let enquiryOrigin = i.enquiryOrigin
            let purchaseItemId = i.id
            let quoteMode = i.quoteMode == null || i.quoteMode == undefined ? 1 : i.enquiryType == 2 ? 2 : i.enquiryType == 3 ? 3 : 1
            this.purchaserInquiryMode = i.enquiryType
            let purchasePrice = {}
            console.log(targetNumItem, targetPriceItem, i.itemForms)


            if (targetNumItem && targetPriceItem && targetSkuUnitItem) {
              num = targetNumItem.filedValue || 0
              price = targetPriceItem.filedValue || 0
              skuUnit = targetSkuUnitItem.textValue || ''

              if (i.enquiryType == 2) {
                console.log('enquiryLadders')
                enquiryLadders.map(item => {
                  item.skuUnit = skuUnit
                })
              }
              if (i.enquiryType == 3) {
                if (i.enquiryOrigin != null && i.enquiryOrigin != undefined) {
                  i.enquiryOrigin.composesList = []
                  i.enquiryOrigin.compose.split(',').map((name, index) => {
                    let cost = null
                    if (name == '原材料费') {
                      cost = { id: 0, name: name }
                    } else if (name == '工序费') {
                      cost = { id: 1, name: name }
                    } else if (name == '管理费') {
                      cost = { id: 2, name: name }
                    } else if (name == '运费') {
                      cost = { id: 3, name: name }
                    } else if (name == '税费') {
                      cost = { id: 4, name: name }
                    }
                    i.enquiryOrigin.composesList.push(cost)
                  })

                }
              }

              console.log(enquiryLadders)

              purchasePrice.enquiryType = i.enquiryType
              purchasePrice.enquiryLadders = enquiryLadders
              purchasePrice.enquiryOrigin = enquiryOrigin

              dataSource = [
                {
                  prePrice: price,
                  num,
                  isEdit: true,
                  skuUnit,
                  preEnquiryLadders: enquiryLadders,
                  preEnquiryOrigin: enquiryOrigin,
                  purchaserInquiryMode: this.purchaserInquiryMode,
                  purchaseItemId: purchaseItemId,
                  enquiryOrigin: {},
                  enquiryLadders: [],
                  quoteMode
                }
              ]
            }
            this.priceBudget += Number(num) * Number(price)
            // 上传图片回显示
            let baseUploadItem = i.itemForms.find(item => item.componentType == 'FILE'), fileList = []
            this.showFile = !!baseUploadItem // 显示文件上传
            if (baseUploadItem && baseUploadItem.filedValue) {
              let fileArray = baseUploadItem.filedValue.split(',')
              fileArray.map((i) => {
                let fileName = i.includes('/images/') ? i.split('/images/')[1] : i.split('/file/')[1]
                fileList.push({
                  fileName,
                  url: i,
                  isImage: fileName.includes('.jpg') || fileName.includes('.png') || fileName.includes('.jpeg')
                })
              })
            }

            let formItemDtoList = i.itemForms.filter(item => item.componentType != 'FILE')

            this.purchaseItem.push({
              purchaseItemId: formItemDtoList[0].purchaseItemId,
              formInfo: {
                arr: formItemDtoList,
                wrapTypeDictName
              },
              formItemDtoList,
              fileList,
              dataSource,
              purchasePrice
            })
          })
          this.purchaseData.itemList = itemList

          this.baseInfo = data
          if (this.quoteId && this.quoteId != 'null') {
            getAction(`/quote/get/${ this.quoteId }`).then(res => {
              const { success, data } = res
              if (success) {
                const { quoteItems, status } = data
                this.quoteStatus = status
                quoteItems.map(i => {
                  const { purchaseItemId, quotePrice, quoteMode } = i
                  this.purchaseItem.map(item => {
                    if (item.purchaseItemId == purchaseItemId) {
                      item.dataSource[0]['price'] = Number(quotePrice)
                    }
                  })
                  this.quoteInquiryMode = quoteMode
                })

                this.quoteData.quoteItems = quoteItems
                this.$forceUpdate()
              }
            })
          }
          if (this.quoteStatus) {
            this.quoteList.map(item => {
              item.quoteStatus = this.quoteStatus
            })
          }

        } else {
          this.$message.warning(message)
        }
      })
    },
    // 计算价格
    handleSumNum() {
      this.$forceUpdate()
      this.baseInfo.priceBudget = this.purchaseItem.reduce((prev, next) => {
        const { packagingQuantity, price } = next
        return prev += Number(price || 0) * Number(packagingQuantity || 0)
      }, 0)
    },
    handleDownLoadFile(url) {
      window.open(url)
    },
    handleChangeQuoteType() {
      this.form.freight = 0
    },
    // 报价
    handleQuote() {

      if (this.formModel == 1) {
        if (!this.formInfo.file) return this.$message.warning('请上传文件')
        postAction('/quote/add-template', {
          purchaseId: this.purchaseId,
          file: this.formInfo.file
        }).then((res) => {
          const { success, message } = res
          if (success) {
            // this.$message.success(message)
            this.$router.push('/quotePriceManagement/quotePriceList')
          } else {
            this.$message.warning(message)
          }
        })
      } else {
        console.log(this.purchaseItem)
        // 阶梯的  1常规
        let hasNoLadderPrice = this.purchaseItem.some((i) => {
          const { dataSource } = i
          const { purchaserInquiryMode, quoteMode, price } = dataSource[0]
          if (purchaserInquiryMode == 2 && quoteMode == 1) {
            return price == 0 || price == null
          }
        })

        console.log(hasNoLadderPrice)
        if (hasNoLadderPrice) {
          return this.$message.warning('存在没有填写的常规报价单价的散件， 请检查')
        }
        // 阶梯的  2阶梯
        let hasNoLadderNum = this.purchaseItem.some((i) => {
          const { dataSource } = i
          const { purchaserInquiryMode, quoteMode, enquiryLadders } = dataSource[0]
          if (purchaserInquiryMode == 2 && quoteMode == 2) {
            return enquiryLadders.some((i) => i.intervalNumber == null) || enquiryLadders.length == 0
          }
        })
        if (hasNoLadderNum) {
          return this.$message.warning('存在没有设置阶梯报价数量散件， 请检查')
        }


        let hasNoPrice = this.purchaseItem.some((i) => {
          const { dataSource } = i
          const { price } = dataSource[0]
          return !price
        })
        let hasNoLadder = this.purchaseItem.some((i) => {
          const { dataSource } = i
          const { purchaserInquiryMode, quoteMode } = dataSource[0]
          let ladder = false
          if (purchaserInquiryMode == 1 && quoteMode == 2) {
            ladder = true
          } else if (purchaserInquiryMode == 2) {
            ladder = true
          }
          console.log('ladder' + ladder)
          return !ladder
        })
        console.log('hasNoLadder' + hasNoLadder)
        // if (hasNoLadder) {
        //   if (hasNoPrice) return this.$message.warning('存在没有填写报价单价的散件， 请检查')
        // }
        let items = this.purchaseItem.map((i) => {
          const { budget, dataSource, purchaseItemId } = i
          const { unitPrice, price, enquiryOrigin, preEnquiryLadders, enquiryLadders } = dataSource[0]
          //阶梯报价中的常规报价
          console.log('i.dataSource[0].preEnquiryLadders')
          console.log(i.dataSource[0].preEnquiryLadders)
          let sortEnquiryLadders = []
          if (i.dataSource[0].purchaserInquiryMode == 2) {
            preEnquiryLadders.map(item => {
              if (item.price) {
                item.unitPrice = parseFloat(item.price)
              }
            })
            if (i.dataSource[0].price != null && i.dataSource[0].price != undefined) {
              i.dataSource[0].price = null
            }
            sortEnquiryLadders = preEnquiryLadders.sort((a, b) => a.intervalNumber - b.intervalNumber)
            sortEnquiryLadders.map((item, index) => {
              if (index == 0 && sortEnquiryLadders[index].intervalNumber && sortEnquiryLadders.length > 1) {
                item.minCount = sortEnquiryLadders[index].intervalNumber
                item.minCompare = '≤'
                item.maxCount = sortEnquiryLadders[index + 1].intervalNumber - 1
                item.maxCompare = '≤'
              } else if (index == 1 && sortEnquiryLadders[index].intervalNumber && sortEnquiryLadders.length == 2) {
                item.minCount = sortEnquiryLadders[index].intervalNumber
                item.minCompare = '≤'
              } else if (index >= 1 && index != sortEnquiryLadders.length - 1 && sortEnquiryLadders[index].intervalNumber) {
                item.minCount = sortEnquiryLadders[index].intervalNumber
                item.minCompare = '≤'
                item.maxCount = sortEnquiryLadders[index + 1].intervalNumber - 1
                item.maxCompare = '≤'
              } else if (index == sortEnquiryLadders.length - 1 && sortEnquiryLadders[index].intervalNumber) {
                item.minCount = sortEnquiryLadders[index].intervalNumber
                item.minCompare = '≤'
              }
            })
          }

          if (enquiryLadders != null && enquiryLadders !== undefined) {
            enquiryLadders.map(item => {
              item.businessId = this.setUserInfo().businessId
              item.purchaseId = this.purchaseId
              item.purchaseItemId = dataSource[0].purchaseItemId
            })
            console.log(enquiryLadders)
            sortEnquiryLadders = enquiryLadders.sort((a, b) => a.intervalNumber - b.intervalNumber)
            console.log(sortEnquiryLadders)
            sortEnquiryLadders.map((item, index) => {
              if (index == 0 && sortEnquiryLadders[index].intervalNumber && sortEnquiryLadders.length > 1) {
                item.minCount = sortEnquiryLadders[index].intervalNumber
                item.minCompare = '≤'
                item.maxCount = sortEnquiryLadders[index + 1].intervalNumber - 1
                item.maxCompare = '≤'
              } else if (index == 1 && sortEnquiryLadders[index].intervalNumber && sortEnquiryLadders.length == 2) {
                item.minCount = sortEnquiryLadders[index].intervalNumber
                item.minCompare = '≤'
              } else if (index >= 1 && index != sortEnquiryLadders.length - 1 && sortEnquiryLadders[index].intervalNumber) {
                item.minCount = sortEnquiryLadders[index].intervalNumber
                item.minCompare = '≤'
                item.maxCount = sortEnquiryLadders[index + 1].intervalNumber - 1
                item.maxCompare = '≤'
              } else if (index == sortEnquiryLadders.length - 1 && sortEnquiryLadders[index].intervalNumber) {
                item.minCount = sortEnquiryLadders[index].intervalNumber
                item.minCompare = '≤'
              }
            })
          }


          if (enquiryOrigin != null && enquiryOrigin != undefined && i.dataSource[0].purchaserInquiryMode == 3) {
            enquiryOrigin.businessId = this.setUserInfo().businessId
            enquiryOrigin.purchaseId = this.purchaseId
            enquiryOrigin.purchaseItemId = dataSource[0].purchaseItemId
            enquiryOrigin.compose = JSON.stringify(i.dataSource[0].enquiryOrigin.compose)
          }


          let quoteMode = 0
          let priceover = 0
          if (dataSource[0].purchaserInquiryMode == 1 && dataSource[0].quoteMode == 1) {
            quoteMode = 1
          } else if (dataSource[0].purchaserInquiryMode == 1 && dataSource[0].quoteMode == 2) {
            quoteMode = 2
          } else if (dataSource[0].purchaserInquiryMode == 2) {
            quoteMode = 2
            priceover = 0

          } else if (dataSource[0].purchaserInquiryMode == 3) {
            quoteMode = 3
          }

          return {
            price: unitPrice || price || budget || priceover,
            purchaseItemId,
            quoteMode: quoteMode,
            enquiryOrigin: enquiryOrigin,
            piQuotesLadderVOs: sortEnquiryLadders.length == 0 ? preEnquiryLadders : sortEnquiryLadders
          }
        })

        let formData = {
          businessId: this.setUserInfo().businessId,
          // freight: 0,
          items,
          purchaseId: this.purchaseId,
          quoteSource: this.quoteType
        }
        postAction('/quote/add', formData).then((res) => {
          const { success, message } = res
          if (success) {
            // this.$message.success(message)
            this.$router.push('/quotePriceManagement/quotePriceList')
          } else {
            this.$message.warning(message)
          }
        })
      }
    },
    // 取消
    handleCancel() {
      this.$router.go(-1)
    },
    toQuoteModel(i, quoteMode, index) {
      this.tabIndex = index
      this.$refs.stairsQuoteModel.handleOpen(i, quoteMode)
    },

    toStairsQuote(item) {
      let quoteDataSourse = { ...item }
      if (quoteDataSourse) {
        console.log(quoteDataSourse)
        console.log(this.purchaseItem)
        this.purchaseItem.dataSource.map(item => {
          quoteDataSourse.filter(item1 => item.purchaseItemId = item1.purchaseItemId).map(item2 => {
            item.enquiryLadders = item2.enquiryLadders
          })
        })
      }

      this.quoteMode = this.purchaseItem.quoteMode
      // if (this.purchaserInquiryMode == 1 && this.quoteMode == 1) {
      //   this.quoteMode = 2
      // } else if (this.purchaserInquiryMode == 2 && this.quoteMode == 1) {
      //   this.quoteMode = 2
      // }
    },
    toQuoteConvModel(index) {
      this.quoteMode = 1
      this.purchaseItem[index].dataSource[0].enquiryLadders = []
      this.purchaseItem[index].dataSource[0].isEdit = true
      console.log(this.purchaseItem[index].dataSource[0].enquiryLadders)

    },
    toCostQuoteModel(i, quoteMode) {
      this.tabIdnex = i
      // const a = JSON.parse(JSON.stringify(i))

      this.$refs.costQuoteModel.handleOpen(this.purchaseItem[i], quoteMode)
    },
    costQuoteOk(item) {
      console.log(item)
      debugger;
      this.purchaseItem[this.tabIdnex] = item
      this.quoteMode = 3
      this.$forceUpdate()
    },
    // handleOk() {
    //   this.$refs.cooperation.handleOpen()
    // },
    onExpand(item) {
      this.quoteItem.expand = !item.expand
    }, toTab(e) {
      if (this.tabIndex != e) {
        console.log('sdfsdsf')
      }
      this.tabIndex = e
      console.log(e)
    },
    changeEnquiryType(e, index) {
      this.formIndex = index
      this.quoteMode = e.target.value
      if (this.quoteMode == 1) {
        this.purchaseItem[this.formIndex].dataSource[0].enquiryLadders = []
      } else if (this.quoteMode == 2) {
        this.purchaseItem[this.formIndex].dataSource[0].price = null
      }
      if (this.purchaseItem[this.formIndex].dataSource[0].purchaserInquiryMode == 2 && this.quoteMode == 2) {

        this.purchaseItem[this.formIndex].dataSource[0].enquiryLadders = JSON.parse(JSON.stringify(this.purchaseItem[this.formIndex].dataSource[0].preEnquiryLadders))
      } else if (this.purchaseItem[this.formIndex].dataSource[0].purchaserInquiryMode == 2 && this.quoteMode == 1) {
        this.purchaseItem[this.formIndex].dataSource[0].enquiryLadders = []
      }

      this.purchaseItem[this.formIndex].dataSource[0].quoteMode = e.target.value

    },
    toAddLadder(formIndex) {
      this.formIndex = formIndex

      console.log(this.purchaseItem[this.formIndex].dataSource[0].enquiryLadders)
      if (this.purchaseItem[this.formIndex].dataSource[0].enquiryLadders.length == 4) {
        return this.$message.warning('只能设置4个区间价格')
      }
      this.purchaseItem[this.formIndex].dataSource[0].enquiryLadders.push({})
      this.$forceUpdate()
    },
    onDeleteLadder() {
      this.purchaseItem[this.formIndex].dataSource[0].enquiryLadders.splice(this.ladderIndex, 1)
      this.showB = false
      this.$forceUpdate()

    },
    toAllTotal(index) {
      this.formIndex = index
      let total = 0
      let compose = this.purchaseItem[this.formIndex].dataSource[0].preEnquiryOrigin.composesList
      if (compose && compose.length > 0) {
        compose.map(item => {
          total += parseFloat(item.unitPrice || 0)
        })
      }
      this.purchaseItem[this.formIndex].dataSource[0].enquiryOrigin.compose = compose
      this.purchaseItem[this.formIndex].dataSource[0].enquiryOrigin.price = total
      this.purchaseItem[this.formIndex].dataSource[0].price = total
      this.purchaseItem[this.formIndex].dataSource[0].enquiryOrigin.businessId = getCurBusinessId()
      this.form.total[this.formIndex] = total
    },
    showBDelete(formIndex, index) {
      this.showB = true
      this.formIndex = formIndex
      this.ladderIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.RequirementConfirmation {
  padding: 24px 24px 40px;
  box-sizing: border-box;
  background: #fff;

  .header {
    &-title {
      color: #090b12;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 15px;

      .copy {
        font-size: 13px;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }

  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;

    &:before {
      background: #FF6026;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 14px;
      color: #8d8e99;
    }
  }

  &-content {
    &-item {
      width: 100%;
      background: #fff;
      position: relative;

      .edit-btn {
        position: absolute;
        top: 4px;
        right: -15px;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep .ant-form-item-label {
    line-height: 22px;

    label {
      color: #626161 !important;
    }
  }

  ::v-deep .ant-form-item-control {
    line-height: 22px;
    color: #131212;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subTitle {
    width: calc(100% - 40px);
    font-size: 15px;
    color: #FF6026;
    padding-bottom: 8px;
    border-bottom: 1px solid #efefef;
    margin: 0 auto 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag-title {
      padding: 2px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background: #fff2ea;
      border-radius: 2px;
    }

    img {
      width: 15px;
      margin-right: 4px;
    }

    span {
      line-height: 22px;
      font-size: 14px;
    }
  }

  .basicInfo-form {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 18px 0 4px;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &-item {
      padding: 0 20px;
    }
  }

  ::v-deep .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-btn-link[disabled] img {
    filter: grayscale(100%);
  }

  .price-title {
    width: 107px;
    margin-top: 8px;
  }

  .process-content {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;

    ::v-deep .ant-timeline-item-tail {
      border: 0.5px dashed #dcdcdc;
    }

    ::v-deep .ant-timeline-item-head {
      width: 5px;
      height: 5px;
      background: #d7d7db;
      border: none;
      left: 2px;
      top: 3px;
    }

    .content {
      padding-top: 5px;
      color: #000000;
      font-size: 14px;
      line-height: 20px;
    }

    .time {
      font-size: 14px;
      line-height: 20px;
      color: #8d8e99;
    }
  }
}

.ant-descriptions {
  // background-color: #f7f8fa;
  // padding: 16px 25px 0;
  // border-radius: 8px;

  &.tag_2 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

::v-deep .ant-descriptions-item-label {
  color: #595959;
}

::v-deep .ant-descriptions-item-content {
  color: #131212;
}

::v-deep .ant-radio-disabled + span {
  color: #262626;
}

.card-view {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px 25px;

  &-item {
    width: calc((100% - 50px) / 3);
    height: 144px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px dashed #e8e9eb;
    padding: 15px;
    display: flex;
    position: relative;

    .cover {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        & + .option-area {
          opacity: 1;
          z-index: 10;
        }
      }
    }

    .option-area {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.65);
      position: absolute;
      left: 15px;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      z-index: -1;

      &:hover {
        opacity: 1;
        z-index: 10;
      }

      img {
        height: 16px;
        cursor: pointer;
      }

      .split-line {
        width: 1px;
        height: 14px;
        background-color: #fff;
        margin: 0 15px;
      }
    }
  }

  .info-card {
    &.inside {
      flex: 1 0 0;
      min-width: 0;
    }

    .sub_title {
      font-size: 14px;
      font-weight: 400;
      color: #131212;
      line-height: 22px;
      margin-bottom: 8px;
      overflow: hidden;
      .text-ellipsis-multi(2);
    }

    div.labels {
      font-size: 12px;
      color: #8d8e99;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.desc-title {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 12px;
  margin-bottom: 15px;

  span.title {
    font-size: 14px;
    color: #131212;
  }
}

div.file-card {
  width: max-content;
  height: 64px;
  background: #fff;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  margin-bottom: 8px;

  &-title {
    margin-bottom: 15px;

    img.icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .fileName {
      color: #1d2129;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-size {
    font-weight: 400;
    color: #8d8e99;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    padding-left: 22px;
  }
}

.coverBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.last-label {
  width: 100%;
  height: 102px;
  background-image: url('~@/assets/longerBG.png');
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  .ant-form-item {
    margin-bottom: 0;
  }

  ::v-deep .ant-input {
    border-color: #ff9a57;
  }

  ::v-deep .ant-input-suffix {
    color: #131212;
  }

  .moneyIcon {
    position: absolute;
    bottom: 0;
    right: 19px;
  }
}

.numberInput {
  width: 320px;
  border-color: #ff7d2f;
  height: 38px;
  line-height: 38px;

  ::v-deep .ant-input-number-input {
    font-size: 18px;
    color: #131212;
    font-weight: 500;
  }
}

.btns {
  text-align: center;
}

.cover-box {
  margin-top: 15px;
  text-align: right;
}

.quote-table {
  padding: 0 15px;
  overflow-x: auto;

  .ant-table-wrapper {
    width: 1056px;
  }

  ::v-deep .ant-table-thead > tr > th {
    background: #F4F5F7;
  }

  ::v-deep .ant-table-footer {
    height: 48px;
    padding: 0;
    width: 100%;

    .footer-info-bar {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 16px;
      box-sizing: border-box;

      .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }

      .total {
        height: 100%;
        border-left: 1px solid #E8E9EB;
        width: 484px;
        line-height: 46px;
        padding-left: 16px;
        color: #FF6026;
        font-size: 16px;
        background-color: #ffffff;
      }

      .total-max {
        height: 100%;
        border-left: 1px solid #E8E9EB;
        width: 394.5px;
        line-height: 46px;
        padding-left: 16px;
        color: #FF6026;
        font-size: 16px;
        background-color: #ffffff;
      }
    }
  }
}

.action-button-area {
  margin-top: 60px;
  text-align: center;

  .ant-btn-primary {
    width: 106px;
    color: #fff;
    height: 38px;
    line-height: 36px;
    font-weight: 600;
  }

  .button-area {
    margin-left: 8px;
    line-height: 22.4px;
    height: 32px;
    padding: unset;
  }
}

@import '~@/styles/form.less';

::v-deep .less-info {
  .ant-table {
    color: rgba(0, 0, 0, 0.35) !important;

  }

  .total {
    color: rgba(255, 96, 38, 0.65) !important;
  }
}

.stairs-quote {
  padding-top: 16px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #FF6026;
  width: 100%;
  justify-content: flex-end;
  padding-right: 520px;
}

.price-range {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: space-between;
}

.more-history {
  height: 22px;
  /* 14/常规 */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* 品牌主题色 */
  color: #FF6026;
  margin-top: 22px;
  justify-content: end;
}

::v-deep .lable-content .ant-form-item-label > label::after {
  content: '';
}

::v-deep .lable-content .ant-col-2 {
  width: 94px !important;
}

::v-deep .ant-table-footer {
  background-color: #ffffff !important;
  padding: 10px 16px;
}

.info-card {
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  display: flex;
  align-items: flex-start;

  img {
    width: 21px;
    height: 21px;
    margin-top: 2px;
  }

  span {
    margin-left: 10px;
  }
}
::v-deep [data-v-583050f0] .ant-descriptions-bordered .ant-descriptions-item-label{
  background-color: #F4F5F7 !important;
 color:  rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

</style>
