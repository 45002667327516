<!--
 * @Description: 订单管理
 * @Author: zhang zhen
 * @Date: 2023-02-15 16:18:10
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-29 12:32:03
 * @FilePath: /page-sass/src/views/requirement/orderManagement/index.vue
-->
<template>
  <div class="infoCard">
    <div class="card-info-box">
      <div class="searchInfo">
        <a-input
          v-model="queryParams.keywords"
          placeholder="请输入案例名称或其他关键词"
          style="width: 480px; height: 38px; line-height: 38px"
        />
        <div class="option-area">
          <a-button type="primary" @click="handleSearch" style="width: 81px">查询</a-button>
          <a-button @click="handleReload" style="width: 81px">重置</a-button>
        </div>
      </div>

    </div>
    <div class="card-info-box">
      <a-button type="primary" @click="addProduct"
                style="width: 136px;height: 40px;margin-bottom: 16px;align-items: center ;display: flex;justify-content: center">
        <img src="@/assets/white-plus.png" style="margin-right:2px ">添加产品
      </a-button>
      <a-table
        rowKey="id"
        :columns="columns"
        :loading="loading"
        :data-source="productList"
        :pagination="false"
        style="margin-bottom: 16px"
        :scroll="{ x: 'max-content' }"
      >

        <template slot="productName" slot-scope="text, record">
          <img :src="record.productPicture[0]" style="width:120px;height:80px;margin-right: 16px;border-radius: 4px">
          {{record.productName}}
          <a-divider type="vertical" />
          {{record.packagingMaterialDictName	}}
          <a-divider type="vertical" />
          {{record.packagingStructureDictName	}}
          <a-divider type="vertical" />
          {{record.processCraftDictName	}}
        </template>
        <template slot="packagingMaterialDictName" slot-scope="text, record">
          <a-divider type="vertical" />
          {{ record.packagingMaterialDictName }}
        </template>

        <template slot="packagingStructureDictName" slot-scope="text, record">
          <a-divider type="vertical" />
          {{ record.packagingStructureDictName }}
        </template>

        <template slot="processCraftDictName" slot-scope="text, record">
          <a-divider type="vertical" />
          {{ record.processCraftDictName }}
        </template>

        <template slot="caseAmount" slot-scope="text, record">
          ￥{{ record.caseAmount }}
        </template>
        <template slot="operation" slot-scope="text, record">
          <a @click="handleUpdateStatus(record,0)" v-if="record.status==1">
            下架
            <a-divider type="vertical" />
          </a>
          <a @click="handleUpdateStatus(record,1)" v-if="record.status==0">
            上架
            <a-divider type="vertical" />
          </a>
          <a @click="toEdit(record)">
            编辑
            <a-divider type="vertical" />
          </a>
          <a-popconfirm placement="topRight" ok-text="确认" cancel-text="取消" @confirm="toDeteleProduct(record)">
            <template slot="title">
              确认要删除吗？
            </template>
            <a >
              删除
            </a>
          </a-popconfirm>
        </template>
      </a-table>
      <ZPagination
        :total="pageNation.total"
        :current="pageNation.pageNum"
        :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage"
      />
    </div>
    <AddProduct ref="addProductModel" @ok="handleLoadData(1)"></AddProduct>
  </div>
</template>

<script>
import InterestedCard from '@/components/tools/interestedCard.vue'
import ZPagination from '@/components/plugins/ZPagination.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters, mapActions } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'

import { postAction, downFiles, getAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import { getCurBusiness, getDateRange } from '@/util/utils'

import dayjs from 'dayjs'
import { size } from 'lodash/collection'
import AddProduct from '@/views/businessManagement/productCase/supplier/modules/addProduct.vue'

export default {
  name: 'orderManagement',
  mixins: [ListMixin],
  components: {
    checkboxSearch,
    InterestedCard,
    ZPagination,
    EmptyArea,
    AddProduct,
    radioGroup

  },
  provide() {
    return {
      handleReloadData: this.handleLoadData
    }
  },
  data() {
    return {
      curBusiness: {},
      isSupplier: false,
      isPurchaser: false,
      disableMixinCreated: true,
      selectedRowKeys: [],
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      showChangeOrder: false,
      visible: false,
      confirmLoading: false,
      rangerDate: [],
      queryParams: {
        status: [],
        orderNo: null
      },
      url: {
        list: '/v2/supplier/product/case/queryByBusinessId'
      },

      CreateTimeScoped: '3',
      orderStatus: '',
      columns: [
        {
          colSpan: 1,
          title: '产品名称',
          dataIndex: 'productName',
          key: 'productName',
          scopedSlots: { customRender: 'productName' },
        },
        {
          title: '案例金额',
          dataIndex: 'caseAmount',
          key: 'caseAmount',
          scopedSlots: { customRender: 'caseAmount' },
          width: 160
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        }
      ], productList: []

    }
  },
  created() {
    this.curBusiness = getCurBusiness()
    this.handleLoadData()
  },
  methods: {
    size,
    dayjs,
    ...mapGetters(['setUserInfo']),
    handleReload() {
      this.queryParams = {
        status: [],
        orderNo: null
      }
      this.CreateTimeScopedRange = []
      this.orderStatus = ''
      this.CreateTimeScoped = '3'
      this.handleLoadData(1)
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${ key }Range`] = []
      }
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      }
      const { keywords } = this.queryParams
      let params = { keywords }

      let pageParams = { ...this.pageNation }
      delete pageParams.total
      this.loading = true
      this.productList = []
      const { pageSize, pageNum } = pageParams
      postAction('/v2/supplier/product/case/pageQuery', {
        businessId: this.curBusiness.businessId,
        size: pageSize,
        current: pageNum,
        // keywords:this.form.keywords,
        ...params
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            this.productList = data.list
            console.log('供应商产品案例list')
            console.log(this.productList)
            this.pageNation = {
              total: res.data.total,
              pageNum: res.data.pageNum,
              pageSize: res.data.pageSize,
            }
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    handleSearch() {
      this.handleLoadData(1)
    }
    , addProduct() {
      console.log('sdfds')
      this.$refs.addProductModel.handleOpen({})
    }, toDeteleProduct(record) {
      getAction('/v2/supplier/product/case/deleteById', { id: record?.id }).then((res) => {
        if (res.success) {
          this.handleLoadData(1)
        } else {
          this.$message.error(res.message)
        }
      })
    }, toEdit(record) {
      console.log(record)
      this.$refs.addProductModel.handleOpen(record)
    },
    handleUpdateStatus(record, status) {
      const payload = {}
      payload.id = record.id
      payload.status = status
      getAction('/v2/supplier/product/case/updateStatus', payload).then(res => {
        const { message, success } = res
        this.loading = false
        if (success) {
          // that.$message.success('保存成功')
          this.handleLoadData(1)
        } else {
          this.$message.warning(message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

// ::v-deep .ant-table-column-title {
//   font-size: 16px;
// }

::v-deep .ant-table-thead {
  tr {
    background: #efefef;

    th {
      border: none;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    // &:not(:last-of-type) > td {
    //   border: none;
    // }

    & > td {
      border-bottom: 1px solid #efefef;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead > tr > th {
  background: #f4f5f7 !important;
  border-bottom: none;
  font-size: 14px;
  font-weight: 600;
  color: #131212;
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    margin-left: 8px;

    .ant-btn {
      width: 97px;
      height: 38px;

      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}

.option-area {
  // margin-bottom: 15px;
  text-align: right;

  .ant-btn-link {
    color: #ff6026;
    font-weight: 400;
  }

  .icon {
    width: 20px;
    margin-right: 2px;
    margin-top: -2px;
  }
}

@import '~@/styles/list.less';

img.newIcon {
  width: 16px;
  margin-left: 6px;
}

.info-card {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .placeholder {
    color: #999;
  }
}

.card-info-box {
  ::v-deep .ant-form-item-label {
    width: 67px !important;
  }
}

.row_title {
  display: flex;
  align-items: center;
}

.card-info-box {
  ::v-deep .ant-badge-dot {
    right: -6px;
  }
}
</style>
