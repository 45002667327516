<template>
  <div class="checkboxSearch">
    <a-checkbox :indeterminate="indeterminate"  v-if="showAll"  :checked="checkAll" @change="onCheckAllChange">
      不限
    </a-checkbox>
    <a-divider type="vertical" />
    <a-checkbox-group :value="arrayValue" @change="onChange" class="info-group">

      <a-checkbox v-for="(item, index) in dictOptions" :key="index" :value="item.dictKey">
        {{ item.dictValue }}
      </a-checkbox>
    </a-checkbox-group>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
export default {
  name: 'checkboxSearch',
  props: {
    dictCode: String,
    placeholder: String,
    disabled: Boolean,
    value: String,
    options: Array,
    needLoad: {
      type: Boolean,
      default: true
    },
    showAll:{  type: Boolean,
      default: true},
    spliter: {
      type: String,
      required: false,
      default: ','
    },
    popContainer: {
      type: String,
      default: '',
      required: false
    },
  },
  data() {
    return {
      indeterminate: false,
      checkAll: !this.value ? true : false,
      dictOptions: [],
      tagType: '',
      arrayValue: !this.value ? [] : this.value.split(this.spliter)
    }
  },
  watch: {
    options: function (val) {
      this.setCurrentDictOptions(val)
    },
    dictCode: {
      immediate: true,
      handler() {
        this.needLoad && this.initDictData()
      }
    },
    value(val) {
      if (!val) {
        this.arrayValue = []
        this.checkAll = true
      } else {
        this.arrayValue = this.value.split(this.spliter)
      }
    }
  },
  created () {
    !this.needLoad && this.setCurrentDictOptions()
  },
  methods: {
    initDictData() {
      getAction('/basic/dict/queryByDictNo', { dictNo: this.dictCode }).then(res => {
        if (res.success) {
          this.dictOptions = res.data
        }
      })
    },
    onChange(selectedValue) {
      //   this.$forceUpdate()
      this.checkAll = false;
      this.indeterminate = selectedValue.length != 0
      this.$emit('change', selectedValue.join(this.spliter))
    },
    setCurrentDictOptions(dictOptions) {
      this.dictOptions = this.options
    },
    getCurrentDictOptions() {
      return this.dictOptions
    },
    getParentContainer(node) {
      if (!this.popContainer) {
        return node.parentNode
      } else {
        return document.querySelector(this.popContainer)
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        arrayValue: [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
      this.$emit('change', "")
    },
  },
  model: {
    prop: 'value',
    event: 'change'
  }
}
</script>
<style lang="less">
::v-deep .ant-select-selection--multiple .ant-select-selection__choice {
  background-color: #f9f9f9 !important;

  .ant-checkbox-wrapper-checked {
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 12px !important;
  }

  .ant-checkbox-checked {
    display: none;
  }
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon {
  display: none;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}

.ant-checkbox-wrapper {
  color: #131212 !important;
  line-height: 30px;
}
.checkboxSearch {
  display: flex;
  .info-group {
    flex: 1 0 0;
    .ant-checkbox-wrapper {
      margin-left: 8px;
    }
  }
}
.checkboxSearch .ant-divider  {
  background: #ECE7E7!important;
  height: 16px;
  margin-top: 8px;
  margin-left: 4px;
  margin-right: 7px;
}
</style>
