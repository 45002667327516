<template>
  <div class="Purchaser-container">
    <a-tabs v-model="activePart" @change="handleChangeTab">
      <a-tab-pane :key="0" >
         <span class="tab-title" slot="tab">
          <span class="title">基础信息</span>
        </span>
        <!-- 基础信息 -->
        <CompanyBasicInfo ref="refComp_0"  />
      </a-tab-pane>
      <a-tab-pane :key="1" >
         <span class="tab-title" slot="tab">
          <span class="title">联系人</span>
        </span>
        <!-- 联系人 -->
        <Contacts ref="refComp_0"  />
      </a-tab-pane>
      <a-tab-pane :key="2" >
         <span class="tab-title" slot="tab">
          <span class="title">商家介绍</span>
        </span>
        <!-- 商家介绍 -->
        <Merchant ref="refComp_0"  />
      </a-tab-pane>
      <a-tab-pane :key="3">
         <span class="tab-title" slot="tab">
          <span class="title">服务内容</span>
        </span>
        <ServiceContent ref="refComp_2" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import CompanyBasicInfo from './companyBasicInfo.vue'
import ServiceContent from './serviceContent.vue'
import { mapGetters } from 'vuex'
import { IdType } from '@/util/constant'
import Contacts from './contacts.vue'
import Merchant from './merchant.vue'
  export default {
    computed: {
      IdType() {
        return IdType
      }
    },
    components: {
      CompanyBasicInfo,
      ServiceContent,
      Contacts,
      Merchant
    },
    data() {
      return {
        activePart: 0,
        status: null,
        roleType: 'PERSON',
      }
    },
    methods: {
      ...mapGetters(['setUserInfo']),
      handleChangeStepInfo({ status }) {
        this.status = status;
        this.activePart = 1;
        this.$nextTick(() => {
         this.$refs[`refComp_1`].isEdit = true
         this.$refs[`refComp_1`].disabled = false
        })
      },
      handleChangeStatus({ status }) {
        this.status = status;
      },
      handleChangeTab(e) {
        // if (e > 0 && !this.status) {
        //   this.activePart = 0
        //   return this.$message.warning({
        //     content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">请先完成企业信息填写</span>,
        //     icon: (h) => <img src="/noPass.png" width="21" height="21" />,
        //   })
        // }
        this.$nextTick(() => {
          this.$refs[`refComp_${e}`] && this.$refs[`refComp_${e}`].handleInitData()
        })
      }
    },
  }
</script>

<style lang="less" scoped>
::v-deep .ant-tabs-nav .ant-tabs-tab {
  padding: 12px 0;
}
::v-deep .ant-tabs-tab {
  padding: 12px 0;

  .tab-title {
    display: flex;
    align-items: center;
  }

  span.title {
    font-size: 16px;
    margin-right: 8px;
    font-weight: 500;
  }
}

</style>
